import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { EventSettingsContext } from "../..";
import MuiTabPanel from "../../../../../../../../components/muiTabPanel";
import PFormReports from "./PFormReports.jsx";
import PFormView from "./PFormView";

const PFormTabs = ({ form }) => {
    const { activeSlide, setSlideType, handleNextClick } = useContext(EventSettingsContext);
    const formDetails = form?.fields;
    const useStyles = makeStyles({
        root: {
            "&.Mui-selected": {
                color: "#FF8359 !important",
                opacity: "1 !important",
                fontWeight: "bold !important"
            },
        },
    });
    const tabStyles = useStyles();

    const [value, setValue] = useState(0);
    const [selectedPollId, setSelectedPollId] = useState(null);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    const handlePollInfoBackClick = () => {
        setTimeout(
            setValue(prevValue => prevValue - 1),
            500
        )
    };

    const handleInfoClick = (pollId) => {
        setSelectedPollId(pollId);
        setValue(1); // Switch to the Poll Report tab
    };

    const handleEditClick = () => {
        setSlideType('editRegistrationForm');
        handleNextClick();
    };

    useEffect(() => {
        return () => {
            setValue(0); // Set 1st tab as default on unmounting
        };
    }, [activeSlide]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <MuiTabPanel value={value} index={0} sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Box sx={{ mx: { xs: 1, md: 2 }, height: '100%' }}>
                    <PFormView formTitle={form.title} formDetails={formDetails} handleEditClick={handleEditClick} handleInfoClick={handleInfoClick} />
                </Box>
            </MuiTabPanel>
            <MuiTabPanel value={value} index={1} sx={{ flexGrow: 1, overflow: 'auto' }}>
                <PFormReports pollId={selectedPollId} handlePollInfoBackClick={handlePollInfoBackClick} />
            </MuiTabPanel>
        </Box>
    );
};

export default PFormTabs;