import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import React from "react";
import QRTicketsPayment from "../../QRTicketsPayment";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import { parseDecimalNumber } from "../../../../../../../../../../../utils/functions/getFormattedNumber";
import CustomTooltip from "../../../../../../../../../../../components/CustomTooltip";

const PurchaseOrder = ({
    totalGuests,
    totalRows,
    totalIncludedFacilityCount,
    additionalFacilityCount,
    ticketFacilityCount,
    calculateAmountToPay,
    loading,
    paymentDone
}) => {
    const theme = useTheme();
    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const [subTotal, emailAndWhatsappCharges, facilitiesCharges, gstCharge, totalAmountToPay] = calculateAmountToPay(true);

    return (
        <Stack spacing={2} sx={{ px: { xs: 0, sm: 1, lg: 2 }, pb: 2 }}>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ mt: 1, color: "#6F738B", fontSize: "0.9rem" }}
                >
                    Please confirm the details before proceeding to payment.
                </Typography>
                <TableContainer
                    sx={{
                        border: "1px solid lightgrey",
                        width: { md: "90%" },
                        margin: "1rem auto !important"
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell align="center">Qty</TableCell>
                                <TableCell align="center">
                                    Per Pass/Ticket Cost
                                </TableCell>
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover={true}>
                                <TableCell>Guests</TableCell>
                                <TableCell align="center">
                                    {totalGuests}
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(
                                            getGeneratedOrSendTicketCharges(
                                                totalGuests
                                            ),
                                            2
                                        )}{" "}
                                        &#8377;
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(
                                            subTotal -
                                                emailAndWhatsappCharges -
                                                facilitiesCharges,
                                            2
                                        )}{" "}
                                        &#8377;
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {ticketFacilityCount > 0 ? (
                                <TableRow hover={true}>
                                    <TableCell>
                                        Facilities
                                        <CustomTooltip
                                            content={
                                                <Stack
                                                    spacing={1}
                                                    sx={{
                                                        p: 0.5,
                                                        marginLeft: "5px"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "0.75rem"
                                                        }}
                                                    >
                                                        It is the total count of facilities for each user
                                                    </Typography>
                                                </Stack>
                                            }
                                            placement="bottom"
                                        ></CustomTooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        {totalIncludedFacilityCount +
                                            additionalFacilityCount}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {parseDecimalNumber(1, 2)} &#8377;
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {parseDecimalNumber(
                                                facilitiesCharges,
                                                2
                                            )}{" "}
                                            &#8377;
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            <TableRow hover={true}>
                                <TableCell>WhatsApp & Email</TableCell>
                                <TableCell align="center">
                                    {totalRows}
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(0.99, 2)} &#8377;
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(
                                            emailAndWhatsappCharges,
                                            2
                                        )}{" "}
                                        &#8377;
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover={true}>
                                <TableCell colSpan={3}>Sub Total</TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(subTotal, 2)}{" "}
                                        &#8377;
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover={true}>
                                <TableCell colSpan={3}>
                                    GST ({GST_PERCENT}%)
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(gstCharge, 2)}{" "}
                                        &#8377;
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover={true}>
                                <TableCell colSpan={3}>
                                    <Typography>Final Amount</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {parseDecimalNumber(
                                            totalAmountToPay,
                                            2
                                        )}{" "}
                                        &#8377;
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {totalAmountToPay > 0 ? (
                <Stack spacing={2}>
                    <Box sx={{ textAlign: "center" }}>
                        <QRTicketsPayment
                            loading={loading}
                            paymentDone={paymentDone}
                        />
                    </Box>
                    <Typography
                        sx={{
                            mt: 1,
                            textAlign: "center",
                            color: "#6F738B",
                            fontSize: "0.8rem"
                        }}
                    >
                        <span
                            style={{
                                color: theme.palette.primaryColor
                            }}
                        >
                            Note:
                        </span>{" "}
                        Minimum 1 Rs. payment will be taken as per razorpay
                        standards!!
                    </Typography>
                </Stack>
            ) : null}
        </Stack>
    );
};

export default PurchaseOrder;
