import React, { useState } from 'react';
import {
    Box,
    CircularProgress,
    useMediaQuery
} from '@mui/material';
import PollReportsTab from './components/PollReportsTab';
import PollsTab from './components/PollViewTab';

const EventPolls = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [selectedPollId, setSelectedPollId] = useState(null);

    const isSmallDevice = useMediaQuery("(max-width:600px)");

    const handleInfoClick = (pollId) => {
        setSelectedPollId(pollId);
        setActiveTab(1); // Switch to the Poll Reports tab
    };

    const handleBackClick = () => {
        setActiveTab(0); // Switch back to the Polls tab
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                key={1}
                role="tabpanel"
                hidden={activeTab !== 0}
                sx={{ width: "70%", justifySelf: 'center', borderRadius: '.25rem' }}
                id={`tabpanel-${1}`}
                aria-labelledby={`tab-${1}`}
            >
                {activeTab === 0 && (
                    <PollsTab handleInfoClick={handleInfoClick} />
                )}
            </Box>
            <Box
                role="tabpanel"
                hidden={activeTab !== 1}
                sx={{ width: "70%", justifySelf: 'center', borderRadius: '.25rem' }}
                id={`tabpanel-${1}`}
                aria-labelledby={`tab-${1}`}

            >
                {activeTab === 1 && (
                    <PollReportsTab pollId={selectedPollId} handleBackClick={handleBackClick} />
                )}
            </Box>
        </Box>
    );
};

export default EventPolls;