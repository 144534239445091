import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import ls from 'local-storage';
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import ResendOtp from "../pages/User/components/VerifyOtp/components/ResendOtp";
import { eventDetails, update_event_details } from '../redux/slices/eventDataSlice';
import { change_login_status, change_user_details } from "../redux/slices/userDataSlice";
import { sendOtp, verifyUser } from "../services/Authentication/user";
import { getEventDetails } from '../services/wEvents/event';
import { codes as countryCodes } from "../utils/constants/countries.codes";
import { PAGE_PATHS } from '../utils/constants/page-path.constants';
import {  getCommonEventGuestDetails } from '../services/wEvents/eventGuests';

const LoginDialog = ({ open, handleClose, guestData }) => {
    const eventData = useSelector(eventDetails)
    const { guestId, commonEventTitle } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState();
    const [contactInfo, setInfo] = useState(false);
    const [sendOtpView, setSendOtpView] = useState(true);
    const [isWhatsApp, setWhatsapp] = useState(true);
    const [loadingSms, setLoadingSms] = useState(false);
    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    const [loadingResendOtp, setLoadingResendOtp] = useState(false);

    const resetOtp = () => {
        setOtp(null);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (sendOtpView) {
            const form = new FormData(e.target);
            const formData = Object.fromEntries(form.entries());
            var isValid;
            if (formData.CountryCode === "91") {
                isValid = /^[0-9]{10}$/.test(formData.MobileNumber); // Indian numbers must have 10 digits
            } else {
                isValid = /^[0-9]{4,13}$/.test(formData.MobileNumber); // Other numbers can have 4 to 13 digits
            }
            if (isValid) {
                if (isWhatsApp) {
                    setLoadingWhatsapp(true);
                } else {
                    setLoadingSms(true);
                }
                formData.otp_method = isWhatsApp ? 'whatsapp' : 'sms';
                sendOtp({
                    dialing_code: formData.CountryCode,
                    mobile: formData.MobileNumber,
                    otp_method: formData.otp_method
                }).then((res) => {
                    console.log('------------>', res);
                    isWhatsApp ? setLoadingWhatsapp(false) : setLoadingSms(false);
                    toast.info("OTP has been sent!", {
                        position: "top-right",
                        theme: "colored"
                    });
                    setSendOtpView(false);
                    setInfo(formData);
                }).catch((err) => {
                    console.log("$$$$$$$$", err);
                    isWhatsApp ? setLoadingWhatsapp(false) : setLoadingSms(false);
                    toast.error(err?.response?.data?.message, {
                        position: "top-right",
                        theme: "dark"
                    });
                })
            } else {
                toast.error("Invalid Mobile Number", {
                    position: "top-right",
                    theme: "dark"
                });
                return;
            }
        } else {
            setLoadingResendOtp(true);
            verifyUser({
                dialing_code: contactInfo.CountryCode,
                mobile: contactInfo.MobileNumber,
                otp: otp
            }).then((res) => {
                console.log('Response ------------>', res);
                ls('UserData', res?.data?.data?.user);
                ls.set('Token', res?.data?.data.token ?? null);
                dispatch(change_login_status(true));
                dispatch(change_user_details(res?.data?.data?.user));
                setLoadingResendOtp(false)
                if (guestId && !eventData?.is_private) { // If it's private event and user access it with common link then don't show this
                    toast.success("OTP verified & successfully logged in", {
                        position: "top-right",
                        theme: "dark"
                    });
                }
                if (commonEventTitle) { // For public event and registration without login process
                    const user_payload = {
                        dialing_code: contactInfo.CountryCode,
                        mobile: contactInfo.MobileNumber
                    }
                    getCommonEventGuestDetails({ eventId: eventData?.id, ...user_payload }).then((result) => {
                        handleClose(result.data.data.uuid);
                        navigate(`/${PAGE_PATHS.GUEST_EVENT_DETAILS.replace(':guestId', result.data.data.uuid)}`)
                        getEventDetails({ guestId: result.data.data.uuid }).then((res) => {
                            setTimeout(() => {
                                dispatch(update_event_details(res.data.data))
                            }, 500);
                        }).catch((err) => {
                            console.log(err);
                            resetOtp();
                            toast.error(err.response.data.error, {
                                position: "top-right",
                                theme: "dark"
                            })
                        })
                        toast.success("OTP verified & successfully logged in", {
                            position: "top-right",
                            theme: "dark"
                        });
                    }).catch((err) => {
                        console.log(err);
                        resetOtp();
                        handleClose();
                        setSendOtpView(true);
                        toast.error(err.response.data.error, {
                            position: "top-right",
                            theme: "dark"
                        });
                    });
                }
                handleClose();
            }).catch((err) => {
                console.log('Error ------------>', err);
                resetOtp();
                setLoadingResendOtp(false)
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose();
            })
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                    }
                }}
                sx={(eventData?.is_private && !guestId) ? {
                    backdropFilter: "blur(20px)",
                    backgroundColor: "#000000a1",
                } : {}}
            >
                <DialogTitle>
                    {
                        (!guestId && eventData?.is_private) ?
                            null :
                            <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                                <CloseIcon fontSize='medium' />
                            </IconButton>
                    }
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>Login to Wowsly</Typography>
                </DialogTitle>

                <DialogContent   sx={{ p: '2rem' }} dividers={true}>
                    <form onSubmit={handleSubmit}>
                        {sendOtpView ?
                            <Stack spacing={{ xs: "1.25rem", sm: "1rem" }} onKeyDown={handleKeyDown}>
                                <Stack direction="row" alignItems='center' justifyContent='center'
                                    spacing={1} mx={1}>
                                    <Box>
                                        <Select
                                            name="CountryCode"
                                            defaultValue={guestData ? guestData.dialing_code : "91"}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: "300px",
                                                        width: "7rem",
                                                        marginTop: "0.5rem",
                                                        border: "1px solid #E4E4E4",
                                                        p: 1.5,
                                                        pt: 0.75,
                                                        boxShadow: "0",
                                                        borderRadius: "4px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 2,
                                                        scrollbarWidth: "0px",
                                                        "&& .Mui-selected": {
                                                            backgroundColor: "#FFEBE4",
                                                            border: "1px solid #E4E4E4",
                                                            "&:hover": {
                                                                backgroundColor: "#FAFBFD"
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            {countryCodes.map((code, index) => (
                                                <MenuItem key={index} value={code.dial_code}>
                                                    <Box>+{code.dial_code}</Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>

                                    <TextField
                                        required
                                        color="warning"
                                        name="MobileNumber"
                                        label="Mobile Number"
                                        inputProps={{
                                            type: "number",
                                            inputMode: "numeric",
                                            pattern: "[0-9]*"
                                        }}
                                        defaultValue={guestData ? guestData.mobile : null}
                                    />
                                </Stack>

                                <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                                    <Typography>Not using whatsapp?</Typography>
                                    <LoadingButton
                                        type="submit"
                                        color="warning"
                                        loading={loadingSms}
                                        onClick={() => setWhatsapp(false)}
                                        startIcon={loadingSms ? <SaveIcon /> : ''}
                                        loadingPosition={loadingSms ? "start" : null}
                                    >
                                        {loadingSms ? 'Sending..' : 'Send SMS'}
                                    </LoadingButton>
                                </Stack>

                                <Box sx={{ textAlign: 'center', mt: 2 }}>
                                    <LoadingButton
                                        type="submit"
                                        color='warning'
                                        loading={loadingWhatsapp}
                                        loadingPosition={loadingWhatsapp ? "start" : null}
                                        variant="contained"
                                        startIcon={loadingWhatsapp ? <SaveIcon /> : ''}
                                    >
                                        {loadingWhatsapp ? 'Sending..' : 'Get OTP on WhatsApp'}
                                    </LoadingButton>
                                </Box>
                            </Stack> :
                            <>
                                <Stack alignItems='center'>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        separator={<Box sx={{ mx: 1 }}></Box>}
                                        isInputNum={true}
                                        inputStyle="otp-input-style"
                                    />
                                </Stack>

                                <ResendOtp reset={resetOtp} user_data={contactInfo} />

                                <Box sx={{ textAlign: 'center' }}>
                                    <LoadingButton
                                        type="submit"
                                        color="warning"
                                        loading={loadingResendOtp}
                                        variant="contained"
                                        disabled={otp?.length === 4 ? false : true}
                                        startIcon={loadingResendOtp ? <SaveIcon /> : ''}
                                        loadingPosition={loadingResendOtp ? "start" : null}
                                    >
                                        {loadingResendOtp ? 'Verifying..' : 'Verify'}
                                    </LoadingButton>
                                </Box>
                            </>
                        }
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
};

export default LoginDialog;
