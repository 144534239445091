import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from "@mui/icons-material/Download";

import {
    IconButton,
    Stack,
    TextField,
    Box,
    Button,
    Chip,
    DialogContent,
    DialogActions,
    Divider,
    ListItem,
    List,
    Typography
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { EventSettingsContext } from '../..';
import AddFormPollsDialog from './AddFormPollsDialog';
import { toast } from "react-toastify";
import { deleteEventPollDetails, downloadEventPollReportsPoll, handleUserResubmitEventPoll, handleUserVisibilityEventPollDetails, handleVisibilityEventPollDetails, insertOrUpdateEventPollDetails } from '../../../../../../../../services/wEvents/event';
import { useSelector } from 'react-redux';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

const PFormView = ({ handleInfoClick }) => {
    const [add, setAdd] = useState(false);
    const { pollsForm } = useContext(EventSettingsContext);
    const eventData = useSelector(eventDetails);
    const fieldDetailsRef = useRef(null)

    let initialValues = {};
    let initialDetails = pollsForm;
    initialDetails?.forEach((r, index) => {
        initialValues[index] = { ...r, exists: true };
        r.name = index;
        r.exists = true;
        r.answer_visible = Boolean(r.answer_visible);
        r.visibility = Boolean(r.visibility);
        r.is_edit = Boolean(r.is_edit);
        r.again_submit = Boolean(r.again_submit);
    });

    const {
        register,
        setValue,
        resetField,
        unregister,
        watch,
        handleSubmit
    } = useForm({
        defaultValues: initialValues
    });

    const initialEditItems = initialDetails.map((item) => ({
        id: item.id,
        edit: false
    }));
    const [editItems, setEditItems] = useState(initialEditItems);
    const [fieldDetails, setFieldDetails] = useState(initialDetails);
    const [chipData, setChipData] = useState([]);
    const [editOptionIndex, setEditOptionIndex] = useState(null);
    const [editOptionValue, setEditOptionValue] = useState("");
    const [visibleItem, setVisibleItem] = useState(null);
    const [reportDownloadReportLoader, setReportDownloadReportLoader] = useState(false)

    const scrollToTop = () => {
        fieldDetailsRef.current.scrollTo(fieldDetailsRef.current.scrollHeight)
    }

    const sortFieldDetails = (details) => {
        const sortedDetails = details.sort((a, b) => {
            if (a.order === null || a.order === 0) return 1;
            if (b.order === null || b.order === 0) return -1;
            return a.order - b.order;
        });

        // Adjust orders to ensure no duplicates
        let lastOrder = 0;
        return sortedDetails.map((item) => {
            if (item.order === null || item.order === 0) {
                return item;
            }
            if (item.order <= lastOrder) {
                item.order = lastOrder + 1;
            }
            lastOrder = item.order;
            return item;
        });
    };

    const adjustOrders = (details, newOrder) => {
        return details.map(item => {
            if (item.order >= newOrder) {
                item.order += 1;
            }
            return item;
        });
    };

    const handleAddFormPoll = (data, chipData) => {
        const lastElementId =
            fieldDetails?.slice(-1)[0]?.id ??
            fieldDetails?.slice(-1)[0]?.name ??
            0;
        const newPoll = {
            name: lastElementId + 1,
            question: data.question,
            type: data.questionType,
            options: chipData.length > 0 ? chipData : undefined,
            mandatory: false,
            exists: false,
            visibility: 0, // Assuming default visibility is 0
            order: Number(data.order), // Convert order to number
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            event_id: eventData?.id,
        };

        // Prepare payload for backend
        const payload = {
            event_id: eventData?.id,
            question: data.question,
            type: data.questionType,
            visibility: 0, // Assuming default visibility is 0
            options: chipData,
            order: Number(data.order) // Convert order to number
        };

        insertOrUpdateEventPollDetails(newPoll, eventData?.id)
            .then((response) => {
                const savedPoll = response.data.data;

                const adjustedDetails = adjustOrders(fieldDetails, savedPoll.order);
                setFieldDetails(sortFieldDetails([savedPoll, ...adjustedDetails]));
                setValue(`${savedPoll.id}`, savedPoll);
                setAdd(false);
                const updatedEditItems = [
                    ...editItems,
                    { id: savedPoll.id, edit: false }
                ];
                setEditItems(updatedEditItems);

                toast.success("Poll saved successfully!", {
                    position: "top-right",
                    theme: "dark"
                });
            })
            .catch((error) => {
                console.error("Error saving question:", error);
                toast.error("Failed to save question to server", {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    const handleEditFormPoll = (item, fieldName) => {
        const newOrder = Number(watch(`${fieldName}.order`));
        const payload = {
            event_id: eventData?.id,
            question: watch(`${fieldName}.question`),
            type: item.type,
            visibility: item.visibility,
            options: chipData,
            order: newOrder, // Convert order to number
            updated_at: new Date().toISOString()
        };

        insertOrUpdateEventPollDetails(payload, eventData?.id, item.id)
            .then((response) => {
                toast.success("Poll edited successfully!", {
                    position: "top-right",
                    theme: "dark"
                });

                const updatedItem = {
                    ...item,
                    question: watch(`${item.name}.question`),
                    options: chipData,
                    order: newOrder, // Convert order to number
                    updated_at: new Date().toISOString()
                };

                const adjustedDetails = adjustOrders(fieldDetails.filter(field => field.id !== item.id), newOrder);
                setFieldDetails(sortFieldDetails([updatedItem, ...adjustedDetails]));

                setValue(`${item.name}.options`, chipData);
                handleCancelEditItem();
            })
            .catch((error) => {
                console.error("Error editing question:", error);
                toast.error("Failed to edit question on server", {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    const handleEditItem = (itemToEdit) => {

        const updatedEditItems = editItems.map((item) => ({
            ...item,
            edit: item.id === (itemToEdit.id ?? itemToEdit.name) ? true : false
        }));

        setEditItems(updatedEditItems);
        setEditOptionIndex(null);
        // For switch type, set fixed options
        if (itemToEdit.type === 'switch') {
            setChipData(["Yes", "No"]);
        } else {
            setChipData(itemToEdit?.options || []);
        }
    };

    const handleCancelEditItem = (fieldName) => {
        const updatedEditItems = editItems.map((item) => ({
            ...item,
            edit: false
        }));
        setEditItems(updatedEditItems);
        resetField(`${fieldName}options`);
        setChipData([]);
    };

    const handleDeleteItem = (item) => {
        const fieldToDelete = fieldDetails.find((d) => d.id === item.id);
        deleteEventPollDetails(eventData?.id, item.id);
        setFieldDetails((details) =>
            details.filter((d) => d !== fieldToDelete)
        );
        unregister(`${item.name}`);
    };

    const handleAddOption = (fieldName) => {
        const option = watch(`${fieldName}options`);
        option && setChipData((chipData) => [...chipData, option]);
        resetField(`${fieldName}options`);
    };

    const handleDeleteOption = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleToggleVisibility = (item) => {
        const updatedFieldsList = fieldDetails.map(field => ({
            ...field,
            visibility: field.id === item.id ? !item.visibility : field.visibility
        }));

        setFieldDetails(updatedFieldsList);

        handleVisibilityEventPollDetails(
            { visibility: item.visibility ? false : true },
            eventData?.id,
            item.id
        ).catch(error => {
            setFieldDetails(prevFields => prevFields.map(field => ({
                ...field,
                visibility: field.id === item.id ? item.visibility : field.visibility
            })));
            toast.error("Failed to update visibility", {
                position: "top-right",
                theme: "dark"
            });
        });
    };

    const handleUserVisibility = async (item) => {
        try {
            const response = await handleUserVisibilityEventPollDetails({ 'answer_visible': +(!item.answer_visible) }, eventData?.id, item.id);
            const updatedItem = { ...item, answer_visible: Boolean(response.data.answer_visible) };
            setFieldDetails(details => [
                updatedItem,
                ...details.filter(field =>
                    field.name !== item.name && field.id !== item.id
                )
            ]);
        } catch (error) {
            console.error("Error updating user visibility:", error);
            toast.error("Failed to update user visibility", {
                position: "top-right",
                theme: "dark"
            });
        }
    };
    const handleResubmitPermission = async (item) => {
        try {
            const response = await handleUserResubmitEventPoll({ 'again_submit': +(!item.again_submit) }, eventData?.id, item.id);

            const updatedAgainSubmit = Boolean(response.data.again_submit);

            setFieldDetails((prevDetails) =>
                prevDetails.map((field) =>
                    field.id === item.id
                        ? { ...field, again_submit: updatedAgainSubmit } // Update the target item
                        : field // Leave other items unchanged
                )
            );
        } catch (error) {
            console.error("Error updating user visibility:", error);
            toast.error("Failed to update user visibility", {
                position: "top-right",
                theme: "dark"
            });
        }
    };

    const renderItem = (item) => {


        const fieldName = item.id;
        const isEditable = item.is_edit
        const isEditing = editItems.find(
            (editItem) => editItem.id === (item.id ?? item.name)
        )?.edit;
        let isSwitch = (item.type === 'switch');


        return (
            <>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ borderRadius: '.5rem', p: '.5rem' }}
                >
                    <Typography><b>{item.order}.</b></Typography>
                    <TextField
                        key={item.question}
                        fullWidth
                        size="small"
                        color="warning"
                        multiline
                        defaultValue={item.question}
                        sx={{ wordBreak: 'break-word' }}
                        inputProps={{
                            readOnly: !isEditing
                        }}

                        {...register(`${fieldName}.question`)}
                    />

                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit">
                            <IconButton
                                size="small"
                                onClick={() => handleEditItem(item)}
                            >
                                <EditIcon color="warning" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                size="small"
                                onClick={() => handleDeleteItem(item)}
                            >
                                <DeleteIcon color="warning" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={item.visibility ? "Hide" : "Show"}>
                            <IconButton
                                size="small"
                                onClick={() => handleToggleVisibility(item)}
                            >
                                {item.visibility ?
                                    <VisibilityIcon color="warning" /> :
                                    <VisibilityOffIcon color="warning" />
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Answers">
                            <IconButton
                                size="small"
                                onClick={() => handleInfoClick(item.id)}
                            >
                                <InfoIcon color="warning" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Show Result to Guest">
                            <Switch
                                size="small"
                                color='warning'
                                sx={{ justifySelf: 'center', alignSelf: 'center' }}
                                checked={item.answer_visible}
                                onChange={() => handleUserVisibility(item)}
                            />
                        </Tooltip>

                        <Tooltip title="Resubmit Poll Ans.">
                            <Switch
                                size="small"
                                color='warning'
                                sx={{ justifySelf: 'center', alignSelf: 'center' }}
                                checked={item.again_submit}
                                onChange={() => handleResubmitPermission(item)}
                            />
                        </Tooltip>
                    </Stack>
                </Stack>

                {(!isEditing) && (
                    <Box sx={{ margin: "1rem auto !important", width: "90%" }}>
                        <List sx={{ listStyleType: 'disc', py: 0, px: '1rem' }}>
                            {item.options?.map((option, i) => (
                                <ListItem key={i} sx={{ display: 'list-item', fontSize: '1.25rem', p: '.05rem' }}>
                                    <Typography fontSize='.90rem'>
                                        {option}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}

                {isEditing && (
                    <Box sx={{
                        margin: "1rem auto !important",
                        width: "90%",
                        borderRadius: "5px",
                        boxShadow: "0 0 3px 3px rgba(0, 0, 50, 0.15)"
                    }}>
                        <Box>
                            <DialogContent>
                                <Stack spacing={1}>
                                    <Stack>
                                        <TextField
                                            fullWidth
                                            color="warning"
                                            label="Set Poll Order"
                                            size='small'
                                            type="number"
                                            defaultValue={item.order}
                                            sx={{ mb: '.5rem' }}
                                            {...register(`${fieldName}.order`)}
                                        />
                                        {!isSwitch && isEditable && (
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={{ xs: 1, sm: 2 }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    color="warning"
                                                    label="Add option"
                                                    size='small'
                                                    sx={{ mb: '.5rem' }}
                                                    inputProps={{
                                                        onKeyDown: (e) => {
                                                            if (e.key === "Enter") {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                document.getElementById("addOption").click();
                                                            }
                                                        }
                                                    }}
                                                    {...register(`${fieldName}options`)}
                                                />
                                                <Button
                                                    id="addOption"
                                                    color="warning"
                                                    sx={{
                                                        fontSize: "2rem",
                                                        p: 0,
                                                        minWidth: "1rem"
                                                    }}
                                                    onClick={() => handleAddOption(fieldName)}
                                                >
                                                    +
                                                </Button>
                                            </Stack>
                                        )}

                                        {chipData.map((option, i) => (
                                            <Stack
                                                key={i}
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                                sx={{ mb: 1 }} // Add margin bottom for spacing between chips
                                            >
                                                {editOptionIndex === i && !isSwitch && isEditable ? (
                                                    <>
                                                        <TextField
                                                            inputRef={(el) => el && el.focus()}
                                                            value={editOptionValue}
                                                            onChange={(e) => setEditOptionValue(e.target.value)}
                                                            fullWidth
                                                            size="small"
                                                            color="warning"
                                                        />
                                                        <Button
                                                            color="warning"
                                                            onClick={() => {
                                                                const updatedOptions = [...chipData];
                                                                updatedOptions[i] = editOptionValue;
                                                                setChipData(updatedOptions);
                                                                setEditOptionIndex(null);
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            color="warning"
                                                            onClick={() => setEditOptionIndex(null)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Chip
                                                            color="warning"
                                                            label={option}
                                                            sx={{ maxWidth: '75%', my: '0.25rem' }}
                                                            onClick={() => {
                                                                setEditOptionIndex(i);
                                                                setEditOptionValue(option);
                                                            }}
                                                        />
                                                        {!isSwitch && isEditable &&
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleDeleteOption(option)()}
                                                            >
                                                                <DeleteIcon color="warning" />
                                                            </IconButton>
                                                        }
                                                    </>
                                                )}
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                                <Button
                                    onClick={() => handleCancelEditItem(fieldName)}
                                    color="warning"
                                    variant="outlined"
                                    sx={{ boxShadow: "none" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="warning"
                                    variant="contained"
                                    onClick={() => handleEditFormPoll(item, fieldName)}
                                    sx={{ boxShadow: "none" }}
                                    disabled={chipData.length < 2 && !isSwitch}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Box>
                    </Box>
                )}
            </>
        );
    };

    const handleSave = (data) => {
        // Implement save functionality here

        toast.success("Form saved successfully", {
            position: "top-right",
            theme: "dark"
        });
    };

    const handleCancel = () => {
        // Implement cancel functionality here

        toast.info("Form edit canceled", {
            position: "top-right",
            theme: "dark"
        });
    };
    const handleDownloadPollReport = () => {
        setReportDownloadReportLoader(true)
        downloadEventPollReportsPoll(eventData?.id)
            .then((response) => {
                const fileBlob = new Blob([response.data], { type: "text/csv" });
                const downloadLink = document.createElement("a");
                document.body.appendChild(downloadLink);
                const fileUrl = URL.createObjectURL(fileBlob);

                downloadLink.href = fileUrl;
                downloadLink.download = `${eventData.title}-PollReports-${moment().format("D-M-YYYY HH-mm")}.csv`;
                downloadLink.click();

                URL.revokeObjectURL(fileUrl);
                document.body.removeChild(downloadLink);
                // setDownloadLoading(false);
                setReportDownloadReportLoader(false)
                // handleDialogClose();
            })
            .catch((err) => {
                console.error('Failed to download poll reports', err);
                // setDownloadLoading(false);
                setReportDownloadReportLoader(false)
                toast.error("Something went wrong", {
                    position: "top-right",
                    theme: "dark"
                });
                // handleDialogClose();
            });
    }
    return (
        <>
            <form autoComplete="off" onSubmit={handleSubmit(handleSave)} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Stack ref={fieldDetailsRef} spacing={2} sx={{ pb: '0.5rem', flexGrow: 1 }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <LoadingButton
                            loading={reportDownloadReportLoader}
                            loadingPosition="start"
                            startIcon={<DownloadIcon />}
                            variant="outlined"
                            color='warning'
                            sx={{ boxShadow: 'none' }}
                            onClick={handleDownloadPollReport}
                        >
                            Download Poll Reports
                        </LoadingButton>
                    </Box>
                    {fieldDetails?.map((item, i) => (
                        <React.Fragment key={i}>
                            {renderItem(item, i)}
                        </React.Fragment>
                    ))}
                    {add ? (
                        <Box
                            sx={{
                                margin: "1rem auto !important",
                                width: "90%",
                                borderRadius: "5px",
                                boxShadow:
                                    "0 0 3px 3px rgba(0, 0, 50, 0.15)"
                            }}
                        >
                            <AddFormPollsDialog
                                setAdd={setAdd}
                                handleAddFormPoll={
                                    handleAddFormPoll
                                }
                            />
                        </Box>
                    ) : (
                        <Button
                            variant="text"
                            color="warning"
                            sx={{ width: "auto" }}
                            onClick={() => setAdd(true)}
                        >
                            + Add Poll
                        </Button>
                    )}
                </Stack>
            </form>
        </>
    )
};

export default PFormView;