import { axiosInstance } from "../../config/interceptor";
import {
    EVENT_API,
    EVENT_GUESTS_API,
    USERS_INVITE_DETAILS,
    USERS_INVITE_MESSAGE
} from "../../utils/constants/apis.urls";

export const verifyGuestQR = (eventId, payload) => {
    return axiosInstance.post(EVENT_GUESTS_API.VERIFY_EVENT_GUESTS.replace(":event_id", eventId), payload);
};

export const verifyGuestData = (guestId, payload) => {
    const eventPayload = {
        qrGuestUuid: guestId,
    }
    return axiosInstance.post(EVENT_GUESTS_API.VERIFY_EVENT_GUESTS.replace(":event_id", payload.eventId), eventPayload);
};

export const getEventGuestCheckInRecords = ({ eventId, guestId }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.CHECK_IN_RECORDS_GUEST.replace(":event_id", eventId)}?guest_id=${guestId}`);
};

export const getScanningReports = ({ eventId, guestId }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.SCANNING_REPORTS.replace(":event_id", eventId)}?guest_id=${guestId}`);
};

export const getCheckInRecords = ({ eventId, ticketId, page }) => {
    if (ticketId) {
        return axiosInstance.get(`${EVENT_GUESTS_API.CHECK_IN_RECORDS.replace(":event_id", eventId).replace(":ticket_id", ticketId)}?page=${page}`);
    }
    return axiosInstance.get(`${EVENT_GUESTS_API.CHECK_IN_RECORDS_WITHOUT_TICKETS.replace(":event_id", eventId)}?page=${page}`);

};

export const getCheckedInTickets = ({ eventId }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.CHECKED_IN_TICKETS.replace(":event_id", eventId)}`);
}

export const getTotalCheckInCount = ({ eventId, ticketId }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.CHECK_IN_RECORDS_COUNT.replace(":event_id", eventId).replace(":ticket_id", ticketId)}`);
};

export const downloadEventCheckIns = (eventId, ticketId) => {
    if (ticketId) {
        return axiosInstance.get(`${EVENT_GUESTS_API.CHECK_IN_RECORDS_DOWNLOAD.replace(':event_id', eventId).replace(":ticket_id", ticketId)}`);
    }
    return axiosInstance.get(`${EVENT_GUESTS_API.CHECK_IN_RECORDS_DOWNLOAD_WITHOUT_TICKETS.replace(':event_id', eventId)}`);
}

export const checkInGuest = (eventId, payload) => {
    return axiosInstance.post(EVENT_GUESTS_API.CHECK_IN_GUEST.replace(":event_id", eventId), payload);
};

//Using this for fetching guest details using uuid so it can fill data directly while login by guest in weblink
export const getGuestDetails = ({ eventId, uuid }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.EVENT_GUESTS_DETAILS.replace(":event_id", eventId).replace(":guest_uuid", uuid)}`);
};

export const getCommonEventGuestDetails = ({ eventId, ...params }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.PUBLIC_EVENT_GUESTS_DETAILS.replace(":event_id", eventId)}?dial_code=${params.dialing_code}&mobile=${params.mobile}`);
};

export const getEventGuestAdditionalDetails = ({ eventId, eventUserId }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.EVENT_GUEST_ADDITIONAL_DETAILS.replace(":event_id", eventId)}?event_user_id=${eventUserId}`)
}

export const getEventGuests = ({ eventId, page, type, searchedGuest }) => {
    if (searchedGuest) {
        return axiosInstance.get(`${EVENT_GUESTS_API.EVENT_GUESTS.replace(":event_id", eventId)}?page=${page}&type=${type}&search=${searchedGuest}`);
    }
    return axiosInstance.get(`${EVENT_GUESTS_API.EVENT_GUESTS.replace(":event_id", eventId)}?page=${page}&type=${type}`);
};

export const createEventGuest = (payload, eventId) => {
    return axiosInstance.post(EVENT_GUESTS_API.EVENT_GUESTS.replace(":event_id", eventId), payload,
        {
            headers: { "content-type": "multipart/form-data" },
        });
};

export const getSubstituteEventGuests = ({ eventId, guestId }) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.SUBSTITUTE_USERS.replace(":event_id", eventId)}?guest_id=${guestId}`);
};

export const substituteEventGuest = (payload, eventId) => {
    return axiosInstance.post(EVENT_GUESTS_API.SUBSTITUTE_EVENT_GUESTS.replace(":event_id", eventId), payload);
};

export const substituteUser = (eventId, payload) => {
    return axiosInstance.post(EVENT_GUESTS_API.SUBSTITUTE_USERS.replace(":event_id", eventId), payload);
};

export const getEventGuestTicketCount = (eventId, guestId) => {
    return axiosInstance.get(`${EVENT_GUESTS_API.EVENT_GUESTS_TICKET_COUNT.replace(":event_id", eventId)}?guest_id=${guestId}`)
}

export const editEventGuest = (payload, eventId, guestId) => {
    return axiosInstance.put(`${EVENT_GUESTS_API.EVENT_GUESTS.replace(":event_id", eventId)}/${guestId}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const deleteEventGuest = (eventId, guestId) => {
    return axiosInstance.delete(`${EVENT_GUESTS_API.EVENT_GUESTS.replace(":event_id", eventId)}/${guestId}`);
};

export const multipleDeleteEventGuests = (payload, eventId) => {
    return axiosInstance.post(EVENT_GUESTS_API.EVENT_GUESTS_DELETE.replace(":event_id", eventId), payload);
};

export const uploadEventGuestCsv = (payload, eventId) => {
    return axiosInstance.post(EVENT_GUESTS_API.EVENT_GUESTS_IMPORT.replace(":event_id", eventId), payload,
        {
            headers: { "content-type": "multipart/form-data" }
        }
    );
};

export const addGroupMembersAsEventGuests = (payload, eventId) => {
    return axiosInstance.post(EVENT_GUESTS_API.EVENT_GUESTS_ADD_GROUP_MEMBERS.replace(":event_id", eventId), payload);
};

export const saveEventGuestInvitation = (payload, eventId) => {
    return axiosInstance.post(USERS_INVITE_MESSAGE.replace(":model_id", eventId), payload,
        {
            headers: { "content-type": "multipart/form-data" }
        }
    );
};

export const getGuestsInvitation = ({ eventId, listType, inviteType }) => {
    return axiosInstance.get(`${USERS_INVITE_MESSAGE.replace(":model_id", eventId)}?model_type=event&invite_type=${inviteType}&list_type=${listType}`);
};

export const getInvitationDetails = ({ eventId, invitationId, page }) => {
    return axiosInstance.get(`${USERS_INVITE_DETAILS.replace(":model_id", eventId).replace(":invite_id", invitationId)}?page=${page}`);
};

export const deleteGuestsInvitation = ({ eventId, invitationId }) => {
    return axiosInstance.delete(USERS_INVITE_DETAILS.replace(":model_id", eventId).replace(":invite_id", invitationId));
};

export const generateQRPassesForEvent = ({ eventId, ticketId, payload }) => {
    return axiosInstance.post(
        EVENT_API.EVENT_TICKET_QR_PASSES.replace(":event_id", eventId).replace(":ticket_id", ticketId),
        payload,
        {
            headers: { "content-type": "multipart/form-data" },
            responseType: "blob"
        }
    );
};

export const sendQRPassesForEvent = ({ eventId, ticketId, payload }) => {
    return axiosInstance.post(
        EVENT_API.EVENT_TICKET_SEND_PASSES.replace(":event_id", eventId).replace(":ticket_id", ticketId),
        payload,
        {
            headers: { "content-type": "multipart/form-data" }
        }
    );
};