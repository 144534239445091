export const questionTypes = [
    {
        "label": "Short Answer",
        "value": "text",
    },
    {
        "label": "Long Answer",
        "value": "textarea",
    },
    {
        "label": "Yes/No Answer",
        "value": "switch",
    },
    {
        "label": "Multiple Choice, Single Answer",
        "value": "radio",
    },
    {
        "label": "Multiple Choice, Multiple Answer",
        "value": "checkbox",
    },
    {
        "label": "File Upload",
        "value": "file",
    },
]
export const pollsType = [
    {
        "label": "Yes/No Poll",
        "value": "switch",
        "defaultOptions": ["Yes", "No"]
    },
    {
        label: "Single Choice Poll",
        value: "radio",
    },
    {
        label: "Multiple Choice Poll",
        value: "checkbox",
    },
]