import {
    Button,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React from "react";
import CustomTooltip from "../../../../../../../../../../components/CustomTooltip";
import { FileUploader } from "react-drag-drop-files";
import GeneratePassesWithFacilityCsv from "../../../../../../../../../../assets/csvfile/Generate-Send Passes Format Wowsly With Facility.csv";
import GeneratePassesWithoutFacilityCsv from "../../../../../../../../../../assets/csvfile/Generate-Send Passes Format Wowsly Without Facility.csv";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";

const PassInputForm = ({
    ticket,
    availablePasses,
    handleFileChange,
    file,
    handleDeleteFile,
    watch,
    register,
    inputErrors,
    setInputErrors,
    setValue
}) => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery("(max-width:600px)");

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        display: "inline-block"
                    }}
                >
                    Choose any one option to generate passes: Upload CSV or Add
                    Quantity
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        display: "inline-block"
                    }}
                >
                    Available:
                </Typography>
                <Typography
                    sx={{
                        ml: 1,
                        color: "#6F738B",
                        display: "inline-block"
                    }}
                >
                    {availablePasses}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontSize: "15px",
                            display: "inline-block"
                        }}
                    >
                        Upload CSV
                    </Typography>
                    <CustomTooltip
                        content={
                            <Stack
                                spacing={1}
                                sx={{
                                    p: 0.5
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem"
                                    }}
                                >
                                    Upload the details in the given CSV format
                                    and get the QR Code values/passes based on
                                    the details uploaded.
                                    <br />
                                    Note: QR Codes generated using this format
                                    will have all the guest details (Guest Name,
                                    Contact Number, Email) fed in it.
                                </Typography>
                            </Stack>
                        }
                        placement="bottom"
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    href={
                        ticket.facilities.length > 0
                            ? GeneratePassesWithFacilityCsv
                            : GeneratePassesWithoutFacilityCsv
                    }
                    download="Guests List.csv"
                    color="warning"
                    variant="outlined"
                    sx={{
                        color: "#FF8359",
                        mb: 2,
                        p: 2
                    }}
                >
                    Download Sample Csv File
                </Button>
            </Grid>
            <Grid item xs={12}>
                <FileUploader
                    name="guestList"
                    handleChange={handleFileChange}
                    types={["csv"]}
                    classes="group-upload-csv-drag-drop-area"
                    maxSize={5}
                    children={
                        <Stack
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                height: "100%",
                                borderRadius: "0.5rem",
                                border: `3.5px dashed ${
                                    watch("passQuantity") > 0
                                        ? "rgba(0, 0, 0, 0.38)"
                                        : theme.palette.primaryColor
                                }`,
                                ":hover": {
                                    cursor:
                                        watch("passQuantity") > 0
                                            ? "not-allowed"
                                            : "pointer"
                                }
                            }}
                        >
                            <DriveFolderUploadIcon
                                sx={{
                                    fontSize: "2rem",
                                    color:
                                        watch("passQuantity") > 0
                                            ? "rgba(0, 0, 0, 0.38)"
                                            : theme.palette.primaryColor
                                }}
                            />
                            <Stack
                                spacing={1}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {file ? (
                                    <CheckCircleIcon color="success" />
                                ) : null}
                                <Typography
                                    sx={{
                                        color: "#6F738B",
                                        fontSize: "0.9rem"
                                    }}
                                >
                                    {file
                                        ? "Uploaded Successfully"
                                        : "Drop or Upload Guest List CSV File"}
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                    onSizeError={() => {
                        toast.error("Maximum upload size for file is 5MB", {
                            position: "top-right",
                            theme: "dark"
                        });
                    }}
                    dropMessageStyle={{
                        backgroundColor: "red"
                    }}
                    disabled={watch("passQuantity") > 0}
                />
                {file ? (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        marginTop={isMobileScreen ? "10px" : "auto"}
                    >
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AttachFileIcon fontSize="small" />
                            <Typography
                                sx={{
                                    color: "#6F738B",
                                    fontSize: "0.9rem"
                                }}
                            >
                                File name: {file.name}
                            </Typography>
                        </Stack>
                        <IconButton onClick={handleDeleteFile} color="warning">
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                <Divider
                    sx={{
                        "&::before, &::after": {
                            // targets divider line before and after text
                            borderColor: "#53433e"
                        },
                        color: "black",
                        fontSize: "15px",
                        gap: isMobileScreen ? "10px" : "25px"
                    }}
                >
                    OR
                </Divider>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontSize: "15px",
                            display: "inline-block"
                        }}
                    >
                        Add Quantity
                    </Typography>
                    <CustomTooltip
                        content={
                            <Stack
                                spacing={1}
                                sx={{
                                    p: 0.5
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem"
                                    }}
                                >
                                    Add the quantity of the number of passes
                                    required and get the QR Code values/passes
                                    based on the quantity provided.
                                    <br />
                                    Note: QR Codes generated using this format
                                    will be generic and won't show any specific
                                    guest details.
                                </Typography>
                            </Stack>
                        }
                        placement="bottom"
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    color="warning"
                    label="Pass Quantity"
                    inputProps={{
                        type: "number"
                    }}
                    {...register("passQuantity", {
                        valueAsNumber: true,
                        onChange: (e) => {
                            const value = e.target.value
                                ? parseInt(e.target.value)
                                : "";
                            setValue("passQuantity", value, {
                                shouldValidate: true
                            });
                            // check if pass quantity is present, greater than 0 and passes are available
                            if (
                                value &&
                                value !== 0 &&
                                value <= availablePasses
                            ) {
                                setInputErrors(null);
                            } else {
                                if (value > availablePasses) {
                                    setInputErrors(
                                        `Pass quantity must be less than ${availablePasses}`
                                    );
                                } else {
                                    setInputErrors(
                                        "Please upload a CSV file or enter a valid number of passes (greater than or equal to 1)."
                                    );
                                }
                            }
                        }
                    })}
                    onInput={(e) => {
                        // Restrict input to numeric values only
                        e.target.value = e.target.value.replace(/\D/g, "");
                    }}
                    sx={{
                        input: {
                            cursor: file ? "not-allowed" : "text"
                        }
                    }}
                    disabled={file ? true : false} // disable if file is uploaded
                />
            </Grid>
            {inputErrors ? (
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <ErrorIcon
                            fontSize="small"
                            sx={{
                                color: "#d32f2f"
                            }}
                        />
                        <Typography
                            component="p"
                            sx={{
                                fontSize: "0.75rem",
                                textAlign: "left",
                                color: "#d32f2f"
                            }}
                        >
                            {inputErrors}
                        </Typography>
                    </Stack>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default PassInputForm;
