import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Box, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import moment from 'moment';
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { EventSettingsContext } from "../..";
import NoRepliesGif from '../../../../../../../../assets/no-replies.gif';
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getSoldEventTicketDetails } from "../../../../../../../../services/wEvents/eventDetails";
import { ticketCurrencies } from '../../../../../../../../utils/constants/ticketCurrency';
import { parseDecimalNumber } from '../../../../../../../../utils/functions/getFormattedNumber';
import { convertUtcToLocal } from '../../../../../../../../utils/functions/timeConversion';

const SoldTicketItem = ({ item }) => {
    const eventData = useSelector(eventDetails);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState([]);
    const eventDetailsObj = useSelector(eventDetails);

    const handleClick = () => {
        setOpen(!open);
        if (!open) {
            setLoader(true);
            getSoldEventTicketDetails(eventData?.id, item.id, eventDetailsObj.is_paid ? "paid" : "free").then((res) => {
                console.log(res.data);
                setLoader(false);
                setDetails(res.data.data);
            }).catch((err) => {
                console.log(err);
                setLoader(false);
            })
        }
    };

    return (
        <>
            <ListItem
                onClick={item ? handleClick : null}
                sx={{
                    px: { xs: 1, md: 1.5 },
                    py: 1,
                    backgroundColor: open ? '#F1F1F1' : 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}
            >
                <ListItemAvatar>
                    {item ? <Avatar sx={{ width: '2rem', height: '2rem' }}><CelebrationRoundedIcon /></Avatar> :
                        <Skeleton animation="wave" variant="circular" width='2.5rem' height='2.5rem' />}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Stack direction='row'>
                            {item ?
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    {item.title}
                                </Typography>
                                : null
                            }
                        </Stack>
                    }
                    secondary={
                        <Stack direction='row'>
                            {item ?
                                <Typography>
                                    {item.sold_out} Sold
                                </Typography>
                                : null
                            }
                        </Stack>
                    }
                />
                {item ? (open ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {loader ?
                        <Skeleton height='10rem' variant='wave' sx={{
                            m: 2, transform: 'scale(1,1)',
                        }} />
                        :
                        <>
                            {details?.length === 0 ?
                                <Box sx={{ p: 2 }}>
                                    <Typography>No one purchased this ticket.</Typography>
                                </Box>
                                :
                                <Box sx={{ px: 1 }}>
                                    <TableContainer sx={{
                                        border: '1px solid lightgrey',
                                        maxWidth: '100%', margin: '1rem auto !important'
                                    }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow hover={true}>
                                                    <TableCell align="center" sx={{
                                                        width: '20%',
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        borderRight: '1px solid #ccc',
                                                        p: { xs: '0.4rem', md: '0.7rem' }
                                                    }}>Name</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        borderRight: '1px solid #ccc',
                                                        p: { xs: '0.4rem', md: '0.7rem' }
                                                    }}>Contact</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        borderRight: '1px solid #ccc',
                                                        p: { xs: '0.4rem', md: '0.7rem' }
                                                    }}>Tickets</TableCell>
                                                    {
                                                        eventDetailsObj.is_paid ?
                                                            <TableCell align="center" sx={{
                                                                fontWeight: 'bold',
                                                                textAlign: 'center',
                                                                borderRight: '1px solid #ccc',
                                                                p: { xs: '0.4rem', md: '0.7rem' }
                                                            }}>Amount</TableCell>
                                                            : <></>
                                                    }
                                                    <TableCell align="center" sx={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        borderRight: '1px solid #ccc',
                                                        p: { xs: '0.4rem', md: '0.7rem' }
                                                    }}>Invited By</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        borderRight: '1px solid #ccc',
                                                        p: { xs: '0.4rem', md: '0.7rem' }
                                                    }}>Registered By</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        p: { xs: '0.4rem', md: '0.7rem' }
                                                    }}>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {details?.map((row, i) => (
                                                    <TableRow hover={true} key={i}>
                                                        <TableCell sx={{
                                                            textAlign: 'center',
                                                            p: { xs: '0.45rem', md: '0.7rem' },
                                                            borderRight: '1px solid #ccc'
                                                        }}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center',
                                                            p: { xs: '0.45rem', md: '0.7rem' },
                                                            borderRight: '1px solid #ccc'
                                                        }}>
                                                            {`+${row.dialing_code} ${row.mobile}`}
                                                        </TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center',
                                                            p: { xs: '0.45rem', md: '0.7rem' },
                                                            borderRight: '1px solid #ccc'
                                                        }}>
                                                            {row.tickets_bought - row.tickets_distributed}
                                                        </TableCell>
                                                        {
                                                            eventDetailsObj.is_paid ?
                                                                <TableCell sx={{
                                                                    textAlign: 'center',
                                                                    p: { xs: '0.45rem', md: '0.7rem' },
                                                                    borderRight: '1px solid #ccc'
                                                                }}>
                                                                    {row.tickets_bought * parseDecimalNumber(row.amount_to_pay, 2)}
                                                                    <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === row.currency)?.symbol }} />
                                                                </TableCell>
                                                                : <></>
                                                        }
                                                        <TableCell sx={{
                                                            textAlign: 'center',
                                                            p: { xs: '0.45rem', md: '0.7rem' },
                                                            borderRight: '1px solid #ccc'
                                                        }}>
                                                            {row.invited_by ?? 'N/A'}
                                                        </TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center',
                                                            p: { xs: '0.45rem', md: '0.7rem' },
                                                            borderRight: '1px solid #ccc'
                                                        }}>
                                                            {row.registered_by ?? 'Self'}
                                                        </TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center',
                                                            p: { xs: '0.45rem', md: '0.7rem' }
                                                        }}>
                                                            {eventDetailsObj.is_paid ?
                                                                (row.payment_time ? moment(new Date(row.payment_time * 1000)).format("D MMM YYYY, h:mm A") : 'N/A') :
                                                                convertUtcToLocal(row.created_at).format("Do MMMM YYYY, h:mm A")
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            }
                        </>
                    }
                </List>
            </Collapse>
            <Divider variant="inset" component="li" sx={{ ml: 0 }} />
        </>
    )
}

const SoldTicketData = () => {
    const { tickets } = useContext(EventSettingsContext);
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    return (
        <>
            {tickets?.length === 0 ? (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 3 }}
                >
                    <Box
                        component="img"
                        src={NoRepliesGif}
                        width={isSmallDevice ? "90%" : "55% !important"}
                        height={isSmallDevice ? "15rem" : "17rem"}
                    />
                </Stack>
            ) : (
                <List
                    sx={{
                        p: 0,
                        width: "100%",
                        overflow: "auto",
                        bgcolor: "background.paper"
                    }}
                >
                    {(tickets ?? Array.from(new Array(5))).map((t, index) => (
                        <SoldTicketItem key={index} item={t} />
                    ))}
                </List>
            )}
        </>
    )
};

export default SoldTicketData;
