import React from "react";
import { useForm } from "react-hook-form";
import { pollsType } from "../../../../../../../../utils/constants/questionTypes";
import { Box, Button, Chip, DialogActions, DialogContent, ListItem, MenuItem, Stack, TextField, FormHelperText } from "@mui/material";

const AddFormPollsDialog = ({ setAdd, handleAddFormPoll }) => {
    const handleClose = () => {
        setAdd(false);
    }
    const { register, watch, getValues, resetField, formState: { errors } } = useForm();

    const addPoll = () => {                 
        const data = getValues();
        data.order = Number(data.order); // Convert order to number
        handleAddFormPoll(data, [...new Set(chipData)]);
    }

    const [chipData, setChipData] = React.useState([]);

    const handleAddOption = () => {
        const option = watch('options');
        option && setChipData((chipData) => [...chipData, option]);
        resetField('options');
    }
    const handleDeleteOption = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleTypeChange = (e) => {
        const selectedType = e.target.value;
        const selectedTypeObj = pollsType.find(type => type.value === selectedType);

        if (selectedType === 'switch') {
            setChipData(selectedTypeObj.defaultOptions);
        } else if (selectedType === 'yesno') {
            setChipData(['Yes', 'No']);
        } else {
            setChipData([]);
        }
    };

    return (
        <Box>
            <DialogContent>
                <Stack spacing={3}>
                    <TextField
                        select
                        fullWidth
                        color="warning"
                        label="Poll Type"
                        defaultValue=""
                        error={!!errors.questionType}
                        helperText={errors.questionType ? "Poll type is required" : ""}
                        {...register('questionType', {
                            required: "Poll type is required",
                            onChange: handleTypeChange
                        })}
                    >
                        {pollsType.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        fullWidth
                        color="warning"
                        label="Type Your Poll Question"
                        inputProps={{
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    document.getElementById('addPoll').click();
                                }
                            },
                        }}
                        {...register("question", { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Set Poll Order"
                        size='small'
                        type="number"
                        {...register("order", { required: true })}
                    />

                    {(watch('questionType') === 'radio' || watch('questionType') === 'checkbox') ?
                        <Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={{ xs: 1, sm: 2 }}>
                                <TextField
                                    fullWidth
                                    color="warning"
                                    label="Add option"
                                    inputProps={{
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                document.getElementById('addOption').click();
                                            }
                                        },
                                    }}
                                    {...register("options")}
                                />
                                <Button id="addOption" color="warning" sx={{ fontSize: '2rem', p: 0, minWidth: '1rem' }}
                                    onClick={handleAddOption}>
                                    +
                                </Button>
                            </Stack>

                            {chipData.length > 0 ?
                                <Stack direction='row' flexWrap='wrap' sx={{ mt: 1, p: 0 }}>
                                    {chipData.map((c, i) => (
                                        <ListItem key={i} sx={{ p: 0.5, width: 'auto' }}>
                                            <Chip
                                                color="warning"
                                                label={c}
                                                onDelete={handleDeleteOption(c)}
                                            />
                                        </ListItem>
                                    ))}
                                </Stack> : null
                            }
                        </Stack> : null}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                <Button onClick={handleClose} color='warning' variant="outlined"
                    sx={{ boxShadow: 'none' }}>
                    Cancel
                </Button>
                <Button id='addPoll' onClick={addPoll} color='warning' variant="contained"
                    sx={{ boxShadow: 'none' }}
                    disabled={
                        !watch('questionType') || !watch('question') ||
                        ((watch('questionType') === 'radio' || watch('questionType') === 'checkbox') && chipData.length <= 1)
                    }>
                    Add
                </Button>
            </DialogActions>
        </Box>
    )
};

export default AddFormPollsDialog;