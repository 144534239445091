import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    Chip,
    ListItem
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { eventDetails, update_event_poll_state } from "../../../../../../../../redux/slices/eventDataSlice";
import { toast } from "react-toastify";
import { insertOrUpdateEventPollDetails } from '../../../../../../../../services/wEvents/event';
import { pollsType } from '../../../../../../../../utils/constants/questionTypes';
import { EventSettingsContext } from '../..';

const CreatePollForm = () => {
    const dispatch = useDispatch();
    const eventData = useSelector(eventDetails);
    const [chipData, setChipData] = useState([]);
    const { activeSlide, setSlide, setPollsForm } = useContext(EventSettingsContext);

    const { register, handleSubmit, watch, setValue, reset, getValues, resetField, formState: { errors } } = useForm({
        defaultValues: {
            question: "",
            questionType: "",
            options: "",
            order: ""
        }
    });

    const handleAddOption = () => {
        const option = watch("options");
        if (option) {
            setChipData([...chipData, option]);
            setValue("options", "");
        }
    };

    const handleDeleteOption = (chipToDelete) => {
        setChipData(chips => chips.filter(chip => chip !== chipToDelete));
    };

    const handleTypeChange = (e) => {
        const selectedType = e.target.value;
        const selectedTypeObj = pollsType.find(type => type.value === selectedType);

        if (selectedType === 'switch') {
            setChipData(selectedTypeObj.defaultOptions);
        } else if (selectedType === 'yesno') {
            setChipData(['Yes', 'No']);
        } else {
            setChipData([]);
        }
    };

    const onSubmit = (data) => {
        const payload = {
            event_id: eventData?.id,
            question: data.question,
            type: data.questionType,
            visibility: 0,
            options: chipData,
            order: data.order
        };

        insertOrUpdateEventPollDetails(payload, eventData?.id)
            .then((res) => {
                setPollsForm([res.data.data])
                toast.success("Poll form created successfully", {
                    position: "top-right",
                    theme: "dark"
                });
                // reset();
                // setChipData([]);
                dispatch(update_event_poll_state(1));
                setSlide(1);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Failed to create poll form", {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [activeSlide]);

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers sx={{
                p: { xs: '1rem', md: '1.5rem' },
                height: { xs: '26rem', md: '31rem' },
                overflow: 'auto'
            }}>
                <Stack spacing={3}>
                    <TextField
                        select
                        fullWidth
                        color="warning"
                        label="Poll Type"
                        defaultValue=""
                        error={!!errors.questionType}
                        helperText={errors.questionType ? "Poll type is required" : ""}
                        {...register('questionType', {
                            required: "Poll type is required",
                            onChange: handleTypeChange
                        })}
                    >
                        {pollsType.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        fullWidth
                        color="warning"
                        label="Type Your Poll Question"
                        inputProps={{
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    document.getElementById('addPoll').click();
                                }
                            },
                        }}
                        {...register("question", { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Set Poll Order"
                        size='small'
                        type="number"
                        {...register("order", { required: true })}
                    />

                    {(watch('questionType') === 'radio' || watch('questionType') === 'checkbox') ?
                        <Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={{ xs: 1, sm: 2 }}>
                                <TextField
                                    fullWidth
                                    color="warning"
                                    label="Add option"
                                    inputProps={{
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                document.getElementById('addOption').click();
                                            }
                                        },
                                    }}
                                    {...register("options")}
                                />
                                <Button id="addOption" color="warning" sx={{ fontSize: '2rem', p: 0, minWidth: '1rem' }}
                                    onClick={handleAddOption}>
                                    +
                                </Button>
                            </Stack>

                            {chipData.length > 0 ?
                                <Stack direction='row' flexWrap='wrap' sx={{ mt: 1, p: 0 }}>
                                    {chipData.map((c, i) => (
                                        <ListItem key={i} sx={{ p: 0.5, width: 'auto' }}>
                                            <Chip
                                                color="warning"
                                                label={c}
                                                onDelete={() => handleDeleteOption(c)}
                                            />
                                        </ListItem>
                                    ))}
                                </Stack> : null
                            }
                        </Stack> : null}
                </Stack>
            </DialogContent>

            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                <Button onClick={() => setSlide(1)} color='warning' variant="outlined"
                    sx={{ boxShadow: 'none' }}>
                    Cancel
                </Button>
                <Button id='addPoll' type="submit" color='warning' variant="contained"
                    sx={{ boxShadow: 'none' }}
                    disabled={
                        !watch('questionType') || !watch('question') || !watch('order') ||
                        ((watch('questionType') === 'radio' || watch('questionType') === 'checkbox') && chipData.length <= 1)
                    }>
                    Create Poll
                </Button>
            </DialogActions>
        </Box>
    );
};

export default CreatePollForm;