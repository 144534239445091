import React from "react";
import { Box } from '@mui/material';

const MuiTabPanel = (props) => {
    const { children, value, index, sx, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={sx}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, mt: 0.5, ...sx }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default MuiTabPanel;
