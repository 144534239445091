import { Box, Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { EventDetailsContext } from "../..";
import { eventDetails } from "../../../../redux/slices/eventDataSlice";

const EventShortcuts = () => {
    const eventData = useSelector(eventDetails);
    const { refs } = useContext(EventDetailsContext);
    const shortcuts = [
        { 'RSVP': 'rsvpBtnRef' },
        ...(eventData?.has_registration) ? [
            { 'Registration Form': 'formBtnRef' }
        ] : [], // As it is independent from free or paid event
        { 'Tickets': 'ticketsBtnRef' },
        ...(eventData?.is_poll) ? [
            { 'Polls': 'pollsBtnRef' },
        ] : [], // As it is independent from free or paid event
        { 'Invitations': 'invitationOptionsRef' },
        { 'Reminders': 'invitationOptionsRef' },
        { 'Check-ins': 'checkInsBtnRef' },
        { 'Payments': 'paymentsBtnRef' },
    ]
    const handleCall = (item) => {
        const refName = Object.values(item)[0];
        if (refName === 'invitationOptionsRef') {
            refs.guestsTabRef.current?.click();
            setTimeout(() => {
                _.get(refs, refName).current?.click()
            }, 500);
        } else if (refName === 'rsvpBtnRef') {
            refs.rsvpBtnRef.current?.click();
        } else {
            refs.settingsBtnRef.current?.click();
            setTimeout(() => {
                _.get(refs, refName).current?.click()
            }, 500);
        }
    }

    return (
        <Box sx={{ my: { xs: 2, md: 3 } }}>
            <Stack direction={'column'} spacing={1}
                sx={{
                    px: 1,
                    py: { xs: 1, sm: 1.5 }, mx: 1,
                    border: '1px solid lightgray'
                }}>
                <Typography sx={{ width: 'fit-content', fontWeight: 600 }}>Quick Actions : </Typography>
                <Stack direction='row' flexWrap='wrap' alignItems='center'
                    rowGap={{ xs: '0.5rem', md: '1rem' }}
                >
                    {shortcuts.map((sc, i) => (
                        <Button
                            key={i}
                            size="small"
                            color="warning"
                            onClick={() => handleCall(sc)}
                            sx={{
                                p: { xs: '0.5rem', sm: '0.5rem' },
                                mr: 2,
                                border: `2px solid ${(Object.keys(sc)[0] === 'RSVP' && !eventData?.is_rsvp_required) ? 'rgba(0, 0, 0, 0.26)' : '#FF8359'}`,
                                borderRadius: '4px',
                                color: '#FF8359',
                                ":hover": {
                                    transform: 'scale(1.01)',
                                    color: 'black',
                                    backgroundColor: '#FFEBE4',
                                    border: '2px solid #FF8359'
                                },
                                transition: 'transform 0.3s, background-color 0.1s ease-in-out',
                            }}
                            disabled={Object.keys(sc)[0] === 'RSVP' && !eventData?.is_rsvp_required}>
                            <Typography sx={{
                                textAlign: 'center',
                                // fontWeight: 'bold',
                                fontSize: { xs: '14px', sm: '1rem' }
                            }}>
                                {Object.keys(sc)[0]}
                            </Typography>
                        </Button>
                    ))}
                </Stack>
            </Stack>
        </Box >
    )
};

export default EventShortcuts;
