import * as yup from "yup";
import ls from 'local-storage'
import { useState } from "react";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { useTheme } from "@emotion/react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, Link } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import ResendOtp from "./ResendOtp";
import { verifyUser } from "../../../../../services/Authentication/user";
import { PAGE_PATHS } from "../../../../../utils/constants/page-path.constants";
import { change_login_status, change_user_details } from "../../../../../redux/slices/userDataSlice";

const OtpVerifyForm = ({ userData }) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const schema = yup.object({
        otp: yup.string().required("Please enter otp!!")
    });

    const {
        handleSubmit,
        formState: { errors },
        control, reset
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        setLoading(true)
        verifyUser({ dialing_code: userData.CountryCode, mobile: userData.MobileNumber, otp: data.otp }).then((res) => {
            console.log('Response ------------>', res);
            setLoading(false)

            let id = res?.data?.data?.user?.id
            ls.set('Token', res?.data?.data.token ?? null)
            dispatch(change_login_status(true))

            if (res?.data?.data?.user?.email) {
                ls('UserData', res?.data?.data?.user)
                dispatch(change_user_details(res?.data?.data?.user))
                toast.success("OTP verified successfully", {
                    position: "top-right",
                    theme: "dark"
                });
                navigate(`/${PAGE_PATHS.DASHBOARD}`);
            } else {
                toast.success("OTP verified and user created", {
                    position: "top-right",
                    theme: "dark"
                });
                navigate(`/${PAGE_PATHS.USER_PROFILE.replace(":id", id)}`, { state: { ...userData, ...data } });
            }

        }).catch((err) => {
            console.log('Error ------------>', err.response);
            reset();
            setLoading(false)
            if (err.response.status == 429) {
                toast.error(err?.response?.data?.message, {
                    position: "top-right",
                    theme: "dark"
                })
                navigate(`/${PAGE_PATHS.MOBILE_LOGIN}`)
            } else {
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            }

        })
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Stack spacing={{ xs: "1rem" }} alignItems='center' justifyContent='center'>
                    <Box>
                        <Controller
                            name="otp"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <OtpInput
                                    value={value}
                                    onChange={onChange}
                                    numInputs={4}
                                    separator={<Box sx={{ mx: 1 }}></Box>}
                                    isInputNum={true}
                                    inputStyle="otp-input-style"
                                    name="otp"
                                />
                            )}
                        />
                        {errors.otp && (
                            <Typography
                                variant="h5"
                                sx={{ mt: 1, color: "red", textAlign: "center" }}
                            >
                                {errors.otp.message}
                            </Typography>
                        )}
                    </Box>

                    <ResendOtp reset={reset} user_data={userData} />

                    <Link underline="none" target="_blank"
                        href='https://api.whatsapp.com/send/?phone=918866260810&text=Hi&type=phone_number&app_absent=0'
                        sx={{
                            pl: '0.25rem',
                            fontSize: '0.85rem',
                            fontFamily: 'Poppins',
                            textAlign: "center",
                            color: theme.palette.primaryColor
                        }}>
                        Not getting OTP? Let us help you.
                    </Link>

                    <Box className="btn-position">
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={loading}
                            variant="contained"
                            startIcon={loading ? <SaveIcon /> : ''}
                            loadingPosition={loading ? "start" : null}
                            sx={{ bgcolor: "#1F274A", color: "white" }}
                        >
                            Verify
                        </LoadingButton>
                    </Box>
                </Stack>
            </form>
        </>
    );
};

export default OtpVerifyForm;
