import React, { useContext, useState } from "react";
import {
    Button,
    Box,
    Dialog,
    DialogContent,
    useTheme,
    IconButton,
    useMediaQuery,
    DialogTitle,
    Typography,
    Divider,
    DialogActions,
    Stack
} from "@mui/material";
import { NoteAddRounded } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { parseDecimalNumber } from "../../../../../../../../../../utils/functions/getFormattedNumber";
import { createOrder } from "../../../../../../../../../../services/Payment/paymentGateway";
import { loadScript } from "../../../../../../../../../../utils/functions/loadScript";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import { userDetails } from "../../../../../../../../../../redux/slices/userDataSlice";
import { razorPayModalConfig } from "../../../../../../../../../../utils/constants/razorPayModalConfig";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import logo from "../../../../../../../../../../assets/WowslyLogo.png";
import PurchaseOrder from "./Steps/PurchaseOrder";
import DownloadQR from "./Steps/DownloadQR";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import WebInvitationStepper from "../WebInvitationStepper";
import MobileInvitationStepper from "../MobileInvitationStepper";
import * as yup from "yup";
import Papa from "papaparse";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { isAdminUser } from "../../../../../../../../../../utils/functions/isAdminUser";
import { generateQRPassesForEvent } from "../../../../../../../../../../services/wEvents/eventGuests";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { EventSettingsContext } from "../../../..";
import InputEmailDialog from "./InputEmailDialog";
import PassInputForm from "./PassInputForm";

const GenerateQRTicketsDialog = ({ ticket }) => {
    const theme = useTheme();
    const availablePasses = ticket?.quantity - ticket?.sold_out;
    const MAX_DIRECT_DOWNLOAD = 100;
    const schema = yup.object({
        passQuantity: yup
            .number()
            .label("Pass quantity")
            .required()
            .integer()
            // .max(500)
            .max(availablePasses)
    });

    const { handleSubmit, register, reset, watch, setValue } = useForm({
        defaultValues: {
            passQuantity: 0
        },
        resolver: yupResolver(schema)
    });

    const eventData = useSelector(eventDetails);
    const userData = useSelector(userDetails);
    const isUserAdmin = isAdminUser(userData?.mobile);
    const isMobileScreen = useMediaQuery("(max-width:600px)");
    const { tickets, setTickets } = useContext(EventSettingsContext);
    const steps = ["Add Quantity", "Make Payment", "Download"];
    const isSmallDevices = useMediaQuery("(max-width:400px)");

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCloseConfirmationDialogOpen, setIsCloseConfirmationDialogOpen] =
        useState(false);
    const [downloadType, setDownloadType] = useState(null);

    const [paymentDone, setPayment] = useState(false);
    const [generateQRActiveStep, setGenerateQRActiveStep] = useState(0);

    const [file, setFile] = useState(null);
    const [inputErrors, setInputErrors] = useState(null);
    const [inputEmailDialog, setInputEmailDialog] = useState(false);

    // these variables are used when input type is file
    const [totalGuestsInCSV, setTotalGuestsInCSV] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    // included facility count multiplied by total guests
    const [totalIncludedFacilityCount, setTotalIncludedFacilityCount] =
        useState(0);
    // additional facility count specified in facilities column (in csv) for each guest
    const [additionalFacilityCount, setAdditionalFacilityCount] = useState(0);

    const totalPasses = file ? totalRows : watch("passQuantity");
    const totalGuests = file ? totalGuestsInCSV : watch("passQuantity");

    const [loader, setLoader] = useState({
        qrPasses: false,
        qrCodeValues: false,
        inputEmail: false
    });
    const [loadingPayment, setLoadingPayment] = useState(false);

    const razorPayCurrency = ticketCurrencies.find(
        (t) => t.value === "rupees"
    )?.currency;

    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const closeDialogConfirmation = () => {
        setIsCloseConfirmationDialogOpen(true);
    };

    const handleCloseDialog = (
        event,
        reason,
        openCloseConfirmationDialog = true // this will be false when download button is clicked
    ) => {
        if (reason && reason === "backdropClick") return;
        if (isUserAdmin) {
            setLoader({ ...loader, qrCodeValues: false, qrPasses: false });
        } else {
            // if the payment is done and event owner attempts to close dialog box then show warning message
            if (
                openCloseConfirmationDialog &&
                ((generateQRActiveStep === 1 && paymentDone) ||
                    generateQRActiveStep === 2) &&
                !isCloseConfirmationDialogOpen
            ) {
                closeDialogConfirmation();
                return;
            }
            setIsCloseConfirmationDialogOpen(false);
            document.body.classList.remove("no-scroll");
            setGenerateQRActiveStep(0);
        }
        setInputErrors(null);
        reset();
        setFile(null);
        setPayment(false);
        setIsDialogOpen(false);
    };

    const validateContact = (user) => {
        // remove all characters other than digits 0 to 9
        const dialingCode = user.dialing_code.trim().replace(/[^0-9]/g, '');
        const mobileNumber = user.mobile.trim().replace(/[^0-9]/g, '');

        // Validate dialing_code
        if (!/^\d{1,4}$/.test(user.dialing_code)) {
            return false;
        }
        // Validate mobile
        if (!/^\d{8,15}$/.test(mobileNumber)) {
            return false;
        }
        // as event owner can not be a guest
        if (dialingCode === eventData?.owner?.dialing_code.toString() && mobileNumber === eventData?.owner?.mobile) {
            return false;
        }

        return true;
    }

    // finds total valid rows, guest and facility count
    const calculateValidGuestData = (data) => {
        let totalGuest = 0;
        let totalRows = 0;
        let includedFacilityCount = 0;
        let additionalFacilitiesCount = 0;
        if (ticket && ticket.facilities) {
            for (let i = 0; i < ticket.facilities.length; i++) {
                if (ticket.facilities[i].is_included === 1) {
                    includedFacilityCount += 1;
                }
            }
        }

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                // check if either name or contact exists and not null
                const contactPresent = ("CountryCode" in data[i] &&
                    "MobileNumber" in data[i] &&
                    data[i]["MobileNumber"] !== "" &&
                    data[i]["CountryCode"] !== "");
                if (
                    ("Name" in data[i] && data[i]["Name"] !== "") || contactPresent
                ) {
                    // check mobile number validity
                    if (contactPresent) {
                        const user = {
                            dialing_code: data[i]["CountryCode"],
                            mobile: data[i]["MobileNumber"]
                        }
                        // console.log("user", user, validateContact(user));
                        if (!validateContact(user)) {
                            continue;
                        }
                    }
                    totalRows += 1;
                    const value = parseInt(data[i]["GuestsAllowedPerTicket"]);
                    if (!isNaN(value)) {
                        // if value is specified in csv file take it
                        totalGuest += value;
                    } else if (data[i]["GuestsAllowedPerTicket"] === "") {
                        // if value is not specified in csv file then consider 1
                        totalGuest += 1;
                    }
                } else {
                    continue;
                }
                if (data[i]["Facilities"]) {
                    totalGuest = totalRows;
                    let facilitiesFromData;
                    try {
                        // Replace curly quotes with straight quotes
                        let facilitiesString = data[i]["Facilities"]
                            .replace(/“/g, '"')
                            .replace(/”/g, '"')
                            .replace(/‘/g, '"')
                            .replace(/’/g, '"');
                        facilitiesFromData = JSON.parse(facilitiesString);
                    } catch (e) {
                        console.error(
                            "Failed to parse facilities from data:",
                            data[i]["Facilities"]
                        );
                        continue;
                    }
                    if (ticket && ticket.facilities) {
                        for (let j = 0; j < ticket.facilities.length; j++) {
                            let facilityNameFromTicket = ticket.facilities[
                                j
                            ].name
                                .trim()
                                .toLowerCase();
                            // Match with facilities from data
                            for (let key in facilitiesFromData) {
                                let facilityNameFromData = key
                                    .trim()
                                    .toLowerCase();
                                if (
                                    facilityNameFromTicket ===
                                        facilityNameFromData &&
                                    facilitiesFromData[key]
                                        .trim()
                                        .toLowerCase() === "yes" &&
                                    ticket.facilities[j].is_included !== 1
                                ) {
                                    additionalFacilitiesCount += 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        setTotalIncludedFacilityCount(includedFacilityCount * totalRows);
        setAdditionalFacilityCount(additionalFacilitiesCount);
        setTotalGuestsInCSV(totalGuest);
        setTotalRows(totalRows);
    };

    const parseCSVFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            // onload event is triggered once file contents are read
            const csvText = e.target.result;
            const { data } = Papa.parse(csvText, { header: true }); // parse csv text to array of objects
            calculateValidGuestData(data);
        };
        reader.readAsText(file); // reads content of file as text
    };

    const handleFileChange = (e) => {
        setInputErrors(null);
        if (e.type !== "text/csv") {
            toast.error("Only CSV file allowed", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        if (e) {
            setInputErrors(null);
            setFile(e);
            setValue("guestList", e);
            parseCSVFile(e);
        }
    };

    const handleDeleteFile = () => {
        setInputErrors(
            "Please upload a CSV file or enter a valid number of passes (greater than or equal to 1)."
        );
        setFile(null);
        setTotalRows(0);
    };

    // if the user is admin generate QR passes/QR code values else perform this function in 3rd step
    // also if total passes to generate are <= 100 then directly download else send temporary downloadable url once they are downloaded
    const generateQRPasses = (values, event, emailList) => {
        if (watch("passQuantity") === 0 && !file) {
            setInputErrors(
                "Please upload a CSV file or enter a valid number of passes (greater than or equal to 1)."
            );
            return;
        }
        if (file && totalGuests > availablePasses) {
            setInputErrors(
                `Pass quantity must be less than ${availablePasses}`
            );
            return;
        }
        const downloadDataType =
            event.nativeEvent?.submitter?.id || event.target?.id;
        setLoader({ ...loader, [downloadDataType]: true });

        const formData = new FormData();
        if (file) {
            formData.append("inputDataMethod", "csv");
            formData.append("guest_list", file);
        } else {
            formData.append("inputDataMethod", "count");
            formData.append("passQuantity", watch("passQuantity"));
        }
        formData.append("downloadDataType", downloadDataType);

        if (totalPasses <= MAX_DIRECT_DOWNLOAD) {
            setLoader({ ...loader, [downloadDataType]: true });
        } else {
            setLoader({ ...loader, inputEmail: true });
            formData.append("email_list", JSON.stringify(emailList)); // it contains list of emails to whom downloadable url must be sent
        }

        generateQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket.id,
            payload: formData
        })
            .then((res) => {
                if (totalPasses <= MAX_DIRECT_DOWNLOAD) {
                    if (downloadDataType === "qrCodeValues") {
                        const fileBlob = new Blob([res.data], {
                            type: "text/csv"
                        });
                        const downloadLink = document.createElement("a");
                        document.body.appendChild(downloadLink);
                        const fileUrl = URL.createObjectURL(fileBlob);

                        // Create a link to download the file
                        downloadLink.href = fileUrl;
                        downloadLink.download = `${eventData.title}-${ticket?.id}-QR Code Values.csv`;
                        // Add the link to the DOM and click it
                        downloadLink.click();

                        // Clean up the URL object
                        URL.revokeObjectURL(fileUrl);
                    } else {
                        const url = window.URL.createObjectURL(
                            new Blob([res.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `${eventData?.title
                                .replaceAll(" ", "_")
                                .toLowerCase()}_passes.zip`
                        );
                        document.body.appendChild(link);
                        link.click();
                    }
                }

                // we will update available tickets even if process runs in background
                // (here there is possibility of error in backend so once the process is completed it will be reflected in UI)
                const ticketIdx = tickets.findIndex((t) => t.id === ticket?.id);
                if (ticketIdx !== -1) {
                    const updatedTickets = [...tickets];
                    updatedTickets[ticketIdx].sold_out += Number(totalGuests);
                    setTickets(updatedTickets);
                }

                let toastMessage = null;
                if (totalPasses <= MAX_DIRECT_DOWNLOAD) {
                    setLoader({ ...loader, [downloadDataType]: false });
                    toastMessage =
                        downloadDataType === "qrCodeValues"
                            ? "CSV for QR Code values generated!!"
                            : "QR passes are generated";
                } else {
                    setLoader({ ...loader, inputEmail: false });
                    toastMessage =
                        downloadDataType === "qrCodeValues"
                            ? "CSV for QR Code values will be sent via email!!"
                            : "QR passes will be sent via email!!";
                }
                toast.success(toastMessage, {
                    position: "top-right",
                    theme: "dark"
                });

                reset();
                handleInputEmailCloseDialog();
                handleCloseDialog();
            })
            .catch(async (err) => {
                console.log(err);
                if (err?.response?.status === 500) {
                    toast.error("Something went wrong. Please try again!!", {
                        position: "top-right",
                        theme: "dark"
                    });                    
                } else if (err?.response?.data && err?.response?.data instanceof Blob) {
                    const text = await err.response.data.text();
                    const jsonData = JSON.parse(text);
                    toast.error(Object.values(jsonData)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                } else if (err.response?.data) {
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                }
                totalPasses <= MAX_DIRECT_DOWNLOAD
                    ? setLoader({ ...loader, [downloadDataType]: false })
                    : setLoader({ ...loader, inputEmail: false });
            });
    };

    // Adding 1 rupee for each facility
    const includedFacilities = ticket.facilities.filter(
        (facility) => facility.is_included === 1
    );

    const calculateAmountToPay = (getAllData = false) => {
        let passSubTotal =
            getGeneratedOrSendTicketCharges(totalGuests) * totalGuests;
    
        const includedFacilityCount = includedFacilities.length;
        // apply 1 RS charge for each facilities
        const totalFacilityCharge = file
            ? (totalIncludedFacilityCount + additionalFacilityCount) * 1
            : includedFacilityCount * watch("passQuantity");
        let subTotal = passSubTotal + totalFacilityCharge;
    
        let gstCharge = (GST_PERCENT / 100) * subTotal;
        gstCharge = parseDecimalNumber(gstCharge, 2);
        const totalAmountToPay = subTotal + gstCharge;
        return getAllData ?
            [passSubTotal, subTotal, includedFacilityCount, totalFacilityCharge, gstCharge, totalAmountToPay] :
            [gstCharge, totalAmountToPay];
    }

    const onSubmit = async (values) => {
        setLoadingPayment(true);
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert(
                "Failed to load Razorpay payment gateway. Please try again later."
            );
            setLoadingPayment(false);
            return;
        }

        const [gstCharge, totalAmountToPay] = calculateAmountToPay();

        const razorPayPayload = {
            payment_amount: totalAmountToPay * 100, // For rupees send in paisa
            amount_currency: razorPayCurrency,
            receipt: "Wowsly_Generate_Passes"
        };
        createOrder(razorPayPayload)
            .then((result) => {
                console.log(result);
                setLoadingPayment(false);
                const order_id = result.data?.id;
                const options = {
                    key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                    amount: totalAmountToPay * 100, // Amount in paise
                    currency: razorPayCurrency,
                    name: "Generate Pass Payment",
                    description: "Payment for generating QR passes",
                    notes: {
                        task: "generate_or_send_qr_passes",
                        user_id: userData?.id,
                        ticket_id: ticket.id,
                        currency: razorPayCurrency,
                        gst_percent:
                            process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE,
                        gst_charges: gstCharge,
                        total_passes: totalGuests,
                        type: "Generate"
                    },
                    image: logo,
                    order_id: order_id,
                    handler: function (response) {
                        console.log("Done", response);
                        if (
                            "razorpay_payment_id" in response &&
                            "razorpay_order_id" in response &&
                            "razorpay_signature" in response
                        ) {
                            setPayment(true); // Mark payment as successful
                        } else {
                            toast.error(
                                "Payment failed due to some issues, please try again later!!",
                                {
                                    position: "top-right",
                                    theme: "dark"
                                }
                            );
                            setLoadingPayment(false);
                            return;
                        }
                    },
                    modal: {
                        ondismiss: function () {
                            setLoadingPayment(false);
                        }
                    },
                    prefill: {
                        name: userData?.full_name,
                        email: userData?.email ?? null,
                        contact: userData?.mobile
                    },
                    theme: {
                        color: theme.palette.primaryColor
                    },
                    config: razorPayModalConfig.paymentMethodsConfiguration
                };
                const razorpay = new window.Razorpay(options);
                razorpay.open();
                razorpay.on("payment.failed", function (response) {
                    console.log(response);
                    setLoadingPayment(false);
                    toast.error(response.error.description, {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            })
            .catch((err) => {
                console.log(err);
                setLoadingPayment(false);
                toast.error(
                    err.response?.data?.error ||
                        "Something went wrong. Please try again!!",
                    {
                        position: "top-right",
                        theme: "dark"
                    }
                );
            });
    };

    const handleNext = () => {
        if (generateQRActiveStep === 0) {
            // isNaN(passQuantity) is to check if passQuantity is empty
            if (
                (watch("passQuantity") === 0 || isNaN(watch("passQuantity"))) &&
                !file
            ) {
                setInputErrors(
                    "Please upload a CSV file or enter a valid number of passes (greater than or equal to 1)."
                );
                return;
            }
            if (inputErrors) {
                return;
            }
            if (file && totalGuests > availablePasses) {
                setInputErrors(
                    `Pass quantity must be less than ${availablePasses}`
                );
                return;
            }
        } else if (generateQRActiveStep === 1 && !paymentDone) {
            toast.error("Please make payment first!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        setGenerateQRActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setGenerateQRActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return (
                    <PassInputForm
                        ticket={ticket}
                        availablePasses={availablePasses}
                        handleFileChange={handleFileChange}
                        file={file}
                        handleDeleteFile={handleDeleteFile}
                        watch={watch}
                        register={register}
                        inputErrors={inputErrors}
                        setInputErrors={setInputErrors}
                        setValue={setValue}
                    />
                );
            case 1:
                return (
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <PurchaseOrder
                            inputType={file ? "csv" : "count"}
                            totalGuests={totalGuests}
                            ticketFacilityCount={ticket.facilities.length}
                            includedFacilities={includedFacilities}
                            totalIncludedFacilityCount={
                                totalIncludedFacilityCount
                            }
                            additionalFacilityCount={additionalFacilityCount}
                            calculateAmountToPay={calculateAmountToPay}
                            loading={loadingPayment}
                            paymentDone={paymentDone}
                        />
                    </Box>
                );
            case 2:
                return (
                    <DownloadQR
                        totalPasses={totalPasses}
                        file={file}
                        ticket={ticket}
                        availablePasses={availablePasses}
                        handleClose={handleCloseDialog}
                        handleSubmit1={handleSubmit}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleInputEmailCloseDialog = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setInputEmailDialog(false);
    };

    // if the user is admin then they can directly generate QR passes/QR code values else user needs to complete stepper function
    return (
        <>
            <Button
                variant="outlined"
                color="warning"
                size="small"
                disabled={availablePasses === 0}
                startIcon={<NoteAddRounded />}
                onClick={() => setIsDialogOpen(true)}
                sx={{ borderColor: "#FF8359", color: "#FF8359" }}
            >
                Generate QR Passes
            </Button>
            {isUserAdmin ? (
                <>
                    <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                        <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                            <IconButton
                                onClick={() => handleCloseDialog()}
                                sx={{
                                    position: "absolute",
                                    right: "0.2rem",
                                    top: "0.2rem"
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.5rem", sm: "1.5rem" }
                                }}
                            >
                                Generate QR Passes
                            </Typography>
                        </DialogTitle>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(generateQRPasses)}
                        >
                            <DialogContent dividers={true}>
                                {availablePasses === 0 ? (
                                    <Typography>
                                        You have generated all the available
                                        passes.
                                    </Typography>
                                ) : (
                                    <Box pb={2}>
                                        <Box>
                                            <PassInputForm
                                                ticket={ticket}
                                                availablePasses={
                                                    availablePasses
                                                }
                                                file={file}
                                                handleFileChange={
                                                    handleFileChange
                                                }
                                                handleDeleteFile={
                                                    handleDeleteFile
                                                }
                                                watch={watch}
                                                register={register}
                                                inputErrors={inputErrors}
                                                setInputErrors={setInputErrors}
                                                setValue={setValue}
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </DialogContent>
                            {availablePasses !== 0 ? (
                                <>
                                    {totalPasses <= MAX_DIRECT_DOWNLOAD && (
                                        <DialogActions
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                my: 0.5
                                            }}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                id="qrCodeValues"
                                                variant="contained"
                                                color="warning"
                                                disabled={
                                                    // disable other button to avoid downloading both buttons simultaneously
                                                    loader.qrCodeValues ||
                                                    loader.qrPasses ||
                                                    availablePasses ===
                                                        0 ||
                                                    (inputErrors ? true : false)
                                                }
                                                loading={
                                                    loader.qrCodeValues
                                                        ? true
                                                        : false
                                                }
                                                loadingPosition={
                                                    loader.qrCodeValues
                                                        ? "start"
                                                        : null
                                                }
                                                startIcon={
                                                    loader.qrCodeValues ? (
                                                        <SaveIcon />
                                                    ) : null
                                                }
                                                sx={{
                                                    fontSize: isSmallDevices
                                                        ? "0.805rem"
                                                        : "auto",
                                                    lineHeight: isSmallDevices
                                                        ? "1.25"
                                                        : "auto"
                                                }}
                                            >
                                                Download CSV
                                            </LoadingButton>

                                            <LoadingButton
                                                type="submit"
                                                id="qrPasses"
                                                variant="contained"
                                                color="warning"
                                                disabled={
                                                    // disable other button to avoid downloading both buttons simultaneously
                                                    loader.qrPasses ||
                                                    loader.qrCodeValues ||
                                                    availablePasses ===
                                                        0 ||
                                                    (inputErrors ? true : false)
                                                }
                                                loading={
                                                    loader.qrPasses
                                                        ? true
                                                        : false
                                                }
                                                loadingPosition={
                                                    loader.qrPasses
                                                        ? "start"
                                                        : null
                                                }
                                                startIcon={
                                                    loader.qrPasses ? (
                                                        <SaveIcon />
                                                    ) : null
                                                }
                                                sx={{
                                                    fontSize: isSmallDevices
                                                        ? "0.805rem"
                                                        : "auto",
                                                    lineHeight: isSmallDevices
                                                        ? "1.25"
                                                        : "auto"
                                                }}
                                            >
                                                Generate & Download Passes
                                            </LoadingButton>
                                        </DialogActions>
                                    )}
                                    {
                                        // if total passes is greater than 100 or is empty
                                        (file
                                            ? totalPasses > MAX_DIRECT_DOWNLOAD
                                            : totalPasses > MAX_DIRECT_DOWNLOAD ||
                                              isNaN(totalPasses)) && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    my: 0.5,
                                                    p: 1
                                                }}
                                            >
                                                <Button
                                                    color="warning"
                                                    id="qrCodeValues"
                                                    variant="contained"
                                                    onClick={() => {
                                                        if (totalPasses > MAX_DIRECT_DOWNLOAD) {
                                                            if (file && totalGuests > availablePasses) {
                                                                setInputErrors(
                                                                    `Pass quantity must be less than ${availablePasses}`
                                                                );
                                                                return;
                                                            }
                                                            setInputEmailDialog(
                                                                true
                                                            );
                                                            setDownloadType(
                                                                "qrCodeValues"
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        fontSize: isSmallDevices
                                                            ? "0.805rem"
                                                            : "auto",
                                                        lineHeight:
                                                            isSmallDevices
                                                                ? "1.25"
                                                                : "auto"
                                                    }}
                                                    disabled={
                                                        inputErrors
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Download CSV
                                                </Button>
                                                <Button
                                                    color="warning"
                                                    id="qrPasses"
                                                    variant="contained"
                                                    onClick={() => {
                                                        if (totalPasses > MAX_DIRECT_DOWNLOAD) {
                                                            if (file && totalGuests > availablePasses) {
                                                                setInputErrors(
                                                                    `Pass quantity must be less than ${availablePasses}`
                                                                );
                                                                return;
                                                            }
                                                            setInputEmailDialog(
                                                                true
                                                            );
                                                            setDownloadType(
                                                                "qrPasses"
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        fontSize: isSmallDevices
                                                            ? "0.805rem"
                                                            : "auto",
                                                        lineHeight:
                                                            isSmallDevices
                                                                ? "1.25"
                                                                : "auto",
                                                        marginLeft: 1
                                                    }}
                                                    disabled={
                                                        inputErrors
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Generate & Download Passes
                                                </Button>
                                            </Box>
                                        )
                                    }
                                    {inputEmailDialog && totalPasses > 0 && (
                                        // get input of emails
                                        <InputEmailDialog
                                            isDialogOpen={inputEmailDialog}
                                            handleCloseDialog={
                                                handleInputEmailCloseDialog
                                            }
                                            loader={loader}
                                            downloadType={downloadType}
                                            handleSubmit={handleSubmit}
                                            onSubmit={generateQRPasses}
                                        />
                                    )}
                                </>
                            ) : null}
                        </form>
                    </Dialog>
                </>
            ) : (
                <>
                    <Dialog
                        open={isDialogOpen}
                        maxWidth="sm"
                        fullWidth
                        onClose={handleCloseDialog}
                        PaperProps={{ style: { borderRadius: 5 } }}
                        disableEscapeKeyDown
                    >
                        <DialogTitle>
                            <IconButton
                                onClick={handleCloseDialog}
                                sx={{
                                    position: "absolute",
                                    right: "0.2rem",
                                    top: "0.2rem"
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.5rem", sm: "1.8rem" }
                                }}
                            >
                                {"Generate QR Process"}
                            </Typography>
                        </DialogTitle>
                        <Divider sx={{ mb: 1 }} />
                        <DialogContent
                            sx={{
                                px: "1rem",
                                py: "0rem",
                                marginBottom:
                                    isMobileScreen && generateQRActiveStep === 0
                                        ? "20px"
                                        : "auto"
                            }}
                        >
                            {isMobileScreen ? (
                                <>
                                    <MobileInvitationStepper
                                        steps={steps}
                                        activeStep={generateQRActiveStep}
                                        getStepContent={getStepContent}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                        paymentDone={paymentDone}
                                    />
                                </>
                            ) : (
                                <>
                                    <WebInvitationStepper
                                        steps={steps}
                                        activeStep={generateQRActiveStep} //edit to activeStep here
                                        getStepContent={getStepContent}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                        paymentDone={paymentDone}
                                    />
                                </>
                            )}
                        </DialogContent>
                    </Dialog>
                    {isCloseConfirmationDialogOpen ? (
                        <Dialog
                            fullWidth
                            open={true}
                            onClose={() =>
                                setIsCloseConfirmationDialogOpen(false)
                            }
                            PaperProps={{
                                sx: {
                                    width: { xs: "80%", sm: "70%", md: "50%" },
                                    m: { xs: 0 }
                                }
                            }}
                        >
                            <DialogContent sx={{ p: "1.5rem 2rem" }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <WarningAmberRoundedIcon
                                        color="warning"
                                        sx={{
                                            marginRight: 1,
                                            fontSize: "4.5rem"
                                        }}
                                    />
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h3">
                                            Are you sure?
                                        </Typography>
                                        <Typography variant="body2">
                                            If you leave this page, you will
                                            have to make the payment again to
                                            Generate Passes. Do you still want
                                            to exit?
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            <Divider />
                            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                                <Button
                                    color="warning"
                                    variant="outlined"
                                    onClick={() => {
                                        handleCloseDialog(false);
                                    }}
                                >
                                    Exit
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() =>
                                        setIsCloseConfirmationDialogOpen(false)
                                    }
                                >
                                    Stay
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null}
                </>
            )}
        </>
    );
};

export default GenerateQRTicketsDialog;
