import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    DialogActions,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../../../../redux/slices/eventDataSlice";
import { generateQRPassesForEvent } from "../../../../../../../../../../../services/wEvents/eventGuests";
import { toast } from "react-toastify";
import { EventSettingsContext } from "../../../../..";
import SaveIcon from "@mui/icons-material/Save";
import GIF from "../../../../../../../../../../../assets/celeb.gif";
import InputEmailDialog from "../InputEmailDialog";
import { useForm } from "react-hook-form";

const DownloadQR = ({
    totalPasses,
    file,
    ticket,
    availablePasses,
    handleClose,
    handleSubmit1
}) => {
    const MAX_DIRECT_DOWNLOAD = 100;
    const eventData = useSelector(eventDetails);
    const { tickets, setTickets } = useContext(EventSettingsContext);

    const [loader, setLoader] = useState({
        qrPasses: false,
        qrCodeValues: false,
        inputEmail: false
    });
    const [downloadType, setDownloadType] = useState(null);

    const [inputEmailDialog, setInputEmailDialog] = useState(false);

    const { handleSubmit } = useForm();

    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const isUltraSmallDevice = useMediaQuery("(max-width:400px)");

    const onSubmit = (values, event, emailList) => {
        // to know what we are downloading (QR Passes or QR Code Values)
        const downloadDataType =
            event.nativeEvent?.submitter?.id || event.target?.id;

        const formData = new FormData();
        if (file) {
            formData.append("inputDataMethod", "csv");
            formData.append("guest_list", file);
        } else {
            formData.append("inputDataMethod", "count");
            formData.append("passQuantity", totalPasses);
        }
        formData.append("downloadDataType", downloadDataType);

        if (totalPasses <= MAX_DIRECT_DOWNLOAD) {
            setLoader({ ...loader, [downloadDataType]: true });
        } else {
            setLoader({ ...loader, inputEmail: true });
            formData.append("email_list", JSON.stringify(emailList));
        }

        generateQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket.id,
            payload: formData
        })
            .then((res) => {
                // download directly if count <= 100 else send temporary downloadable link via mail
                if (totalPasses <= MAX_DIRECT_DOWNLOAD) {
                    if (downloadDataType === "qrCodeValues") {
                        const fileBlob = new Blob([res.data], {
                            type: "text/csv"
                        });
                        const downloadLink = document.createElement("a");
                        document.body.appendChild(downloadLink);
                        const fileUrl = URL.createObjectURL(fileBlob);

                        // Create a link to download the file
                        downloadLink.href = fileUrl;
                        downloadLink.download = `${eventData.title}-${ticket?.id}-QR Code Values.csv`;
                        // Add the link to the DOM and click it
                        downloadLink.click();

                        // Clean up the URL object
                        URL.revokeObjectURL(fileUrl);
                    } else {
                        const url = window.URL.createObjectURL(
                            new Blob([res.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `${eventData?.title
                                .replaceAll(" ", "_")
                                .toLowerCase()}_passes.zip`
                        );
                        document.body.appendChild(link);
                        link.click();
                    }
                }
                const ticketIdx = tickets.findIndex((t) => t.id === ticket?.id);
                if (ticketIdx !== -1) {
                    const updatedTickets = [...tickets];
                    updatedTickets[ticketIdx].sold_out += Number(totalPasses);
                    setTickets(updatedTickets);
                }
                let toastMessage = null;
                if (totalPasses <= MAX_DIRECT_DOWNLOAD) {
                    setLoader({ ...loader, [downloadDataType]: false });
                    toastMessage =
                        downloadDataType === "qrCodeValues"
                            ? "CSV for QR Code values generated!!"
                            : "QR passes are generated";
                } else {
                    setLoader({ ...loader, inputEmail: false });
                    toastMessage =
                        downloadDataType === "qrCodeValues"
                            ? "CSV for QR Code values will be sent via email!!"
                            : "QR passes will be sent via email!!";
                }
                toast.success(toastMessage, {
                    position: "top-right",
                    theme: "dark"
                });

                handleClose(null, null, false); // to avoid opening close confirmation dialog
            })
            .catch((err) => {
                console.log("Error object:", err);
                if (err.response && err.response.data) {
                    const errorData = Object.values(err.response.data);
                    const errorMessage =
                        Array.isArray(errorData) &&
                        errorData.length > 0 &&
                        Array.isArray(errorData[0]) &&
                        errorData[0].length > 0
                            ? errorData[0][0]
                            : "Something went wrong. Please try again!!";
                    toast.error(errorMessage, {
                        position: "top-right",
                        theme: "dark"
                    });
                } else {
                    toast.error("Something went wrong. Please try again!!", {
                        position: "top-right",
                        theme: "dark"
                    });
                }
                totalPasses <= MAX_DIRECT_DOWNLOAD
                    ? setLoader({ ...loader, [downloadDataType]: false })
                    : setLoader({ ...loader, inputEmail: false });
            });
    };

    const handleInputEmailCloseDialog = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setInputEmailDialog(false);
    };

    return (
        <>
            <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{ py: { xs: 2 }, textAlign: "center" }}
            >
                <Box
                    component="img"
                    src={GIF}
                    alt={"Loading"}
                    width={isSmallDevice ? "100%" : "25rem"}
                    height={isSmallDevice ? "10rem" : "15rem"}
                />
                <Typography>
                    Congratulations, you have successfully completed all the
                    steps, including payment. You can now continue to generate
                    QR Codes.
                </Typography>
                {totalPasses <= MAX_DIRECT_DOWNLOAD && (
                    <DialogActions>
                        <>
                            <LoadingButton
                                type="button"
                                id="qrCodeValues"
                                variant="contained"
                                color="warning"
                                onClick={(e) => handleSubmit(onSubmit(null, e))}
                                disabled={
                                    loader.qrCodeValues ||
                                    loader.qrPasses ||
                                    availablePasses === 0
                                }
                                loading={loader.qrCodeValues ? true : false}
                                loadingPosition={
                                    loader.qrCodeValues ? "start" : null
                                }
                                startIcon={
                                    loader.qrCodeValues ? <SaveIcon /> : null
                                }
                                sx={{
                                    fontSize: isUltraSmallDevice
                                        ? "0.805rem"
                                        : "auto",
                                    lineHeight: isUltraSmallDevice
                                        ? "1.25"
                                        : "auto"
                                }}
                            >
                                Download CSV
                            </LoadingButton>
                            <LoadingButton
                                type="submit"
                                id="qrPasses"
                                variant="contained"
                                color="warning"
                                onClick={(e) => handleSubmit(onSubmit(null, e))}
                                disabled={
                                    loader.qrPasses ||
                                    loader.qrCodeValues ||
                                    availablePasses === 0
                                }
                                loading={loader.qrPasses ? true : false}
                                loadingPosition={
                                    loader.qrPasses ? "start" : null
                                }
                                startIcon={
                                    loader.qrPasses ? <SaveIcon /> : null
                                }
                                sx={{
                                    fontSize: isUltraSmallDevice
                                        ? "0.805rem"
                                        : "auto",
                                    lineHeight: isUltraSmallDevice
                                        ? "1.25"
                                        : "auto"
                                }}
                            >
                                Generate & Download Passes
                            </LoadingButton>
                        </>
                    </DialogActions>
                )}
                {totalPasses > MAX_DIRECT_DOWNLOAD && (
                    <Box display="flex">
                        <Button
                            color="warning"
                            variant="contained"
                            onClick={() => {
                                setInputEmailDialog(true);
                                setDownloadType("qrCodeValues");
                            }}
                            sx={{
                                fontSize: isUltraSmallDevice
                                    ? "0.805rem"
                                    : "auto",
                                lineHeight: isUltraSmallDevice ? "1.25" : "auto"
                            }}
                        >
                            Download CSV
                        </Button>
                        <Button
                            color="warning"
                            id="qrPasses"
                            variant="contained"
                            onClick={() => {
                                setInputEmailDialog(true);
                                setDownloadType("qrPasses");
                            }}
                            sx={{
                                fontSize: isUltraSmallDevice
                                    ? "0.805rem"
                                    : "auto",
                                lineHeight: isUltraSmallDevice
                                    ? "1.25"
                                    : "auto",
                                marginLeft: 1
                            }}
                        >
                            Generate & Download Passes
                        </Button>
                    </Box>
                )}
                {inputEmailDialog && (
                    // get input of emails
                    <InputEmailDialog
                        isDialogOpen={inputEmailDialog}
                        handleCloseDialog={handleInputEmailCloseDialog}
                        loader={loader}
                        downloadType={downloadType}
                        handleSubmit={handleSubmit1}
                        onSubmit={onSubmit}
                    />
                )}
            </Stack>
        </>
    );
};

export default DownloadQR;
