import { Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { EventDetailsContext } from '../..';
import MuiTabPanel from "../../../../components/muiTabPanel";
import { eventDetails } from "../../../../redux/slices/eventDataSlice";
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";
import { mapEventTabs } from '../../../../utils/functions/mapEventTabs';
import { userStatus } from '../../../../redux/slices/userDataSlice';
import CreateEditViewDialog from './CreateEditViewDialog';

const useStyles = makeStyles({
    root: {
        "&.Mui-selected": {
            color: "#FF8359 !important",
            opacity: "1 !important",
            fontWeight: "bold !important"
        },
    },
});

const EventDetailTabs = ({ tabValue, handleTabChange }) => {
    const c = useStyles();
    const eventData = useSelector(eventDetails);
    const isLoggedIn = useSelector(userStatus);
    const { refs } = useContext(EventDetailsContext);


    return (
        <>
            {Object.keys(eventData).length > 0 ?
                <>
                    <Stack
                        direction='row'
                        alignItems='center'
                        className='menubar-sticky'
                    >
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            // scrollable
                            scrollButtons={false}
                            // breakpoint="sm"
                            // allowScrollButtonsMobile
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#FF8359"
                                }
                            }}
                            sx={{
                                // overflowX: 'auto',
                                width: '80vw !important',
                                flexGrow: 1,
                                backgroundColor: '#FFEBE4',
                                maxWidth: { sm: '100%' },
                                // [`& .${tabsClasses.scrollButtons}`]: {
                                //     "&.Mui-disabled": { opacity: 0.3 }
                                // },
                                // '.Mui-selected': {
                                //     fontWeight: "bold !important",
                                //     color: '#FF8359 !important'
                                // },
                                // '.MuiTabs-scrollButtons': {
                                //     display: { sm: 'none !important' },
                                // }
                            }}
                        >
                            {eventData.tabs.map((tab, index) => (
                                <Tab id={tab.title}
                                    ref={index === 1 ? refs.guestsTabRef : null} // TODO: Change this logic when tabs will have dynamic order
                                    key={index} className={c.root}
                                    label={tab.title === 'Events' ? 'Info' : tab.title}
                                    sx={{
                                        color: '#FF8359',
                                        fontWeight: 'bold',
                                        opacity: '1',
                                        textTransform: 'none',
                                    }}
                                />
                            )
                            )}
                        </Tabs>
                        {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                            <CreateEditViewDialog views={eventData.tabs[0].views} /> : null
                        }
                    </Stack>

                    {eventData.tabs.map((tab, index) => (
                        <MuiTabPanel key={index} value={tabValue} index={index}>
                            {mapEventTabs(tab?.title, tab?.views, isLoggedIn)}
                            {/* This will render relative component based on event API response */}
                        </MuiTabPanel>
                    ))}
                </> :
                <Skeleton height='10rem' sx={{ mx: 2 }} />
            }
        </>
    )
};

export default EventDetailTabs;
